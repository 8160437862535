import Ajv from 'ajv'
import uriToHttp from './uriToHttp'
import { BACKEND_URL } from 'constants/Constants'

export interface Token {
  name: string
  symbol: string
  decimals: number
  address: string
  price_quote: number
  is_low_liquidity: boolean
}
export interface TokenList {
  readonly data: {
    readonly common_tokens: Token[]
    readonly tokens: Token[]
    readonly whitelisted_tokens: Token[]
  }
  readonly status: number
  readonly message: string
  readonly success: boolean
  readonly tokens: {
    readonly [address: string]: Token
  }
}

const schema = {
  type: 'object',
  properties: {
    data: {
      type: 'object',
      properties: {
        common_tokens: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              symbol: { type: 'string' },
              decimals: { type: 'number' },
              address: { type: 'string' },
              price_quote: { type: 'number' },
              is_low_liquidity: { type: 'boolean' },
            },
            required: ['name', 'symbol', 'decimals', 'address', 'price_quote', 'is_low_liquidity'],
            additionalProperties: false,
          },
        },
        tokens: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              symbol: { type: 'string' },
              decimals: { type: 'number' },
              address: { type: 'string' },
              price_quote: { type: 'number' },
              is_low_liquidity: { type: 'boolean' },
            },
            required: ['name', 'symbol', 'decimals', 'address', 'price_quote', 'is_low_liquidity'],
            additionalProperties: false,
          },
        },
        whitelisted_tokens: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              symbol: { type: 'string' },
              decimals: { type: 'number' },
              address: { type: 'string' },
              price_quote: { type: 'number' },
              is_low_liquidity: { type: 'boolean' },
            },
            required: ['name', 'symbol', 'decimals', 'address', 'price_quote', 'is_low_liquidity'],
            additionalProperties: false,
          },
        },
      },
      required: ['common_tokens', 'tokens', 'whitelisted_tokens'],
      additionalProperties: false,
    },
    status: { type: 'number' },
    message: { type: 'string' },
    success: { type: 'boolean' },
  },
  required: ['data', 'status', 'message', 'success'],
  additionalProperties: false,
}

const tokenListValidator = new Ajv({ allErrors: true }).compile(schema)

/**
 * Contains the logic for resolving a list URL to a validated token list
 * @param listUrl list url
 * @param resolveENSContentHash resolves an ens name to a contenthash
 */
export default async function getChronosTokenList(): Promise<TokenList> {
  const listUrl = BACKEND_URL + '/pairs/tokens'
  const urls = uriToHttp(listUrl)

  for (let i = 0; i < urls.length; i++) {
    const url = urls[i]
    const isLast = i === urls.length - 1
    let response
    try {
      response = await fetch(url)
    } catch (error) {
      console.debug('Failed to fetch list', listUrl, error)
      if (isLast) throw new Error(`Failed to download list ${listUrl}`)
      continue
    }

    if (!response.ok) {
      if (isLast) throw new Error(`Failed to download list ${listUrl}`)
      continue
    }

    const json = await response.json()
    if (!tokenListValidator(json)) {
      const validationErrors: string =
        tokenListValidator.errors?.reduce<string>((memo, error) => {
          const add = `${error.dataPath} ${error.message ?? ''}`
          return memo.length > 0 ? `${memo}; ${add}` : `${add}`
        }, '') ?? 'unknown error'
      throw new Error(`Token list failed validation: ${validationErrors}`)
    }
    // map the list of tokens to a dict
    // ex: { '0x1234...': { name: '...', symbol: '...', ... }, ... }

    const tokens = json.data.tokens.reduce((memo: { [address: string]: Token }, token: Token) => {
      memo[token.address.toLowerCase()] = token
      return memo
    }, {})
    return { ...json, tokens }
  }
  throw new Error('Unrecognized list URL protocol.')
}
