import 'inter-ui'
import React, { StrictMode } from 'react'
import { isMobile } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import './i18n'
import App from './pages/App'
import store from './state'
import GlobalUpdater from './state/global/updater'
import UserUpdater from './state/user/updater'
import VoterUpdater from './state/voter/updater'
import { MulticallUpdater } from './lib/state/multicall'
import ProtocolUpdater from './state/protocol/updater'
import TokenUpdater from './state/tokens/updater'
import PoolUpdater from './state/pools/updater'
import GaugesUpdater from './state/gauges/updater'
import ApplicationUpdater from './state/application/updater'
import ListUpdater from './state/lists/updater'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import { ApolloProvider } from '@apollo/client/react'
import { arbitrumClient } from 'apollo/client'
// import { BlockNumberProvider } from 'lib/hooks/useBlockNumber'
import Web3Provider from 'components/Web3Provider'
// import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
// import { Web3Provider } from '@ethersproject/providers'
// import { SupportedChainId } from 'constants/chains'
// import { NetworkContextName } from './constants'

// const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

window.addEventListener('error', (error) => {
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true,
  })
})

function Updaters() {
  return (
    <>
      <GlobalUpdater />
      <ListUpdater />
      <UserUpdater />
      <ProtocolUpdater />
      <TokenUpdater />
      <PoolUpdater />
      <GaugesUpdater />
      <ApplicationUpdater />
      <VoterUpdater />
      <MulticallUpdater />
    </>
  )
}
// function getLibrary(provider: any): Web3Provider {
//   const library = new Web3Provider(provider, SupportedChainId.ARBITRUM_ONE)
//   library.pollingInterval = 12000
//   return library
// }

ReactDOM.render(
  <StrictMode>
    <FixedGlobalStyle />
    <ApolloProvider client={arbitrumClient}>
      <Provider store={store}>
        <Web3Provider>
          {/* <BlockNumberProvider> */}
          <Updaters />
          <ThemeProvider>
            <ThemedGlobalStyle />
            <HashRouter>
              <App />
            </HashRouter>
          </ThemeProvider>
          {/* </BlockNumberProvider> */}
        </Web3Provider>
      </Provider>
    </ApolloProvider>
  </StrictMode>,
  document.getElementById('root')
)
