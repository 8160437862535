import { Address } from 'wagmi'
import { TokenData } from 'types'

export const MAX_PAIRS_QUERY = 280n

export const BACKEND_URL = 'https://dexapi.chronos.exchange'
export const COINGECKO_URL = 'https://api.coingecko.com/api/v3'

export const MAINNET_CHAIN_ID = 42161
export const IMG_SIZE = 0.7
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'
export const CHR_ADDRESS = '0x15b2fb8f08e4ac1ce019eadae02ee92aedf06851'
export const WETH_ADDRESS = '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'

export const ETH_TOKEN: TokenData = {
  name: 'ETH',
  symbol: 'ETH',
  decimals: 18,
  address: NULL_ADDRESS,
  price_quote: 0,
}

// Default pair tokens
/*export const DEFAULT_PAIR_TOKEN_0: TokenData = {
  name: 'WETH',
  symbol: 'WETH',
  decimals: 18,
  address: process.env.NEXT_PUBLIC_WETH_ADDRESS as Address,
};*/
export const DEFAULT_PAIR_TOKEN_0: TokenData = ETH_TOKEN

export const DEFAULT_PAIR_TOKEN_1: TokenData = {
  name: 'CHR',
  symbol: 'CHR',
  decimals: 18,
  address: process.env.NEXT_PUBLIC_CHR_ADDRESS as Address,
  price_quote: 0,
}

export const EPOCH_0_START = 1682546401

export const WHITELISTED = [
  'WETH',
  'USDC',
  'USDT',
  'DAI',
  'WBTC',
  'UNI',
  'ARB',
  'LDO',
  'TUSD',
  'LINK',
  'FRAX',
  'USDD',
  'CRV',
  'GMX',
  'Frax Share',
  'frxETH',
  'sfrxETH',
  'MAI',
  'USD+',
  'DAI+',
  'DOLA',
  'rETH',
  'CHR',
  'SHRAP',
  'IBEX',
  'TAROT',
  'OATH',
  'GRAIN',
  'GMD',
  'gmdUSDC',
  'gmUSD',
  'PENDLE',
  'DEUS',
  'DEI',
  'FCTR',
  'Y2K',
  'RDNT',
  'LUSD',
  'plsARB',
  'ARKEN',
  'LIBERA',
  'IDIA',
  'POI$ON',
  'iPOI$ON',
  'pGOLD',
  'pSLVR',
  'pOIL',
  'pTSLA',
  'pAMZN',
  'pAAPL',
  'pGOOG',
  'pMSFT',
  'wUSDR',
  'THOREUM',
  'LEVI',
  'MIM',
  'LQTY',
  'GRT',
  'JGLP',
  'fUSDC',
  'pxGLP',
  'ACS',
  'elCHR',
  'keyCHR',
  'LQDR',
  'GMD',
  'GND',
  'xGND',
  'FXS',
  'FBA',
  'RING',
  'FIS',
  'StaFirETH',
  'TND',
  'MAI',
  'QI',
  'PEPE',
  'L2DAO',
  'plsRDNT',
  'PLS',
  'PORK',
  'DCA',
  'xPORK',
  'BIFI',
  'JRT',
  'jEUR',
  'ANGLE',
  'agEUR',
  'XCAD',
  'GB',
  'BELA',
  'cpCHR',
  'BETS',
  'KUJI',
  'RPL',
  'HASH',
  'CELLS',
  'POLY',
  'USDF',
  'gOHM',
  'OHM',
  'WAR',
  'gDAI',
  'GNS',
  'ERN',
  'CRUIZE',
  'tBTC',
  'USDC.e',
  'XEX',
  'multiACS',
  'T',
  'PUSD',
  'PRISM',
  'TAROTv2',
  'oldLQDR',
  'LQDRV2',
]

export const BLACKLISTED = ['LIBERA', 'DEI', 'keyCHR']
