export enum SupportedChainId {
  MAINNET = 1,
  ARBITRUM_ONE = 42161,
  OPTIMISM = 10,
  CELO = 42220,
}
export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.CELO]: 'celo',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.OPTIMISM]: 'optimism',
} as const
