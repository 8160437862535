import {
  updateSelectedEpoch,
  updateSelectedEpochGaugesVotingDetails,
  updateTotalVeCHR,
  updateVeCHRForSelectedEpoch,
} from './actions'
import { AppState, AppDispatch } from './../index'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AssociatedGaugeVotingDetails } from 'types/votes'

// export function useProtocolData(): [ProtocolData | undefined, (protocolData: ProtocolData) => void] {
//   const [activeNetwork] = useActiveNetworkVersion()
//   const protocolData: ProtocolData | undefined = useSelector(
//     (state: AppState) => state.protocol[activeNetwork.id]?.data
//   )

//   const dispatch = useDispatch<AppDispatch>()
//   const setProtocolData: (protocolData: ProtocolData) => void = useCallback(
//     (protocolData: ProtocolData) => dispatch(updateProtocolData({ protocolData, networkId: activeNetwork.id })),
//     [activeNetwork.id, dispatch]
//   )
//   return [protocolData, setProtocolData]
// }

export function useTotalVeChr(): [number | undefined, (totalVeCHR: number | undefined) => void] {
  const veChrAmount: number | undefined = useSelector((state: AppState) => state.voter.totalVeCHR)

  const dispatch = useDispatch<AppDispatch>()
  const _updateTotalVeCHR: (totalVeCHR: number | undefined) => void = useCallback(
    (totalVeCHR: number | undefined) => dispatch(updateTotalVeCHR({ totalVeCHR })),
    [dispatch]
  )
  return [veChrAmount, _updateTotalVeCHR]
}

export function useSelectedEpoch(): [number, (selectedEpoch: number) => void] {
  const selectedEpoch: number = useSelector((state: AppState) => state.voter.selectedEpoch)

  const dispatch = useDispatch<AppDispatch>()
  const _updateSelectedEpoch: (selectedEpoch: number) => void = useCallback(
    (selectedEpoch: number) => dispatch(updateSelectedEpoch({ selectedEpoch })),
    [dispatch]
  )

  return [selectedEpoch, _updateSelectedEpoch]
}

export function useAssociatedGaugeVotingDetails(): [
  AssociatedGaugeVotingDetails[],
  (details: AssociatedGaugeVotingDetails[]) => void
] {
  const details: AssociatedGaugeVotingDetails[] = useSelector(
    (state: AppState) => state.voter.selectedEpochGaugesVotingDetails
  )
  const dispatch = useDispatch<AppDispatch>()
  const _updateSelectedEpochGaugesVotingDetails: (details: AssociatedGaugeVotingDetails[]) => void = useCallback(
    (details: AssociatedGaugeVotingDetails[]) => dispatch(updateSelectedEpochGaugesVotingDetails({ details })),
    [dispatch]
  )

  return [details, _updateSelectedEpochGaugesVotingDetails]
}

export function useTotalVeCHRForSelectedEpoch(): [
  number | undefined,
  (totalVeCHRForSelectedEpoch: number | undefined) => void
] {
  const totalVeCHRForSelectedEpoch: number | undefined = useSelector(
    (state: AppState) => state.voter.totalVeCHRForSelectedEpoch
  )

  const dispatch = useDispatch<AppDispatch>()
  const _updateVeCHRForSelectedEpoch: (totalVeCHRForSelectedEpoch: number | undefined) => void = useCallback(
    (totalVeCHRForSelectedEpoch: number | undefined) =>
      dispatch(updateVeCHRForSelectedEpoch({ totalVeCHRForSelectedEpoch: totalVeCHRForSelectedEpoch })),
    [dispatch]
  )

  return [totalVeCHRForSelectedEpoch, _updateVeCHRForSelectedEpoch]
}
