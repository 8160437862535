import { initializeConnector } from '@web3-react/core'
import { Network } from '@web3-react/network'
import { Actions, Connector } from '@web3-react/types'

import { RPC_PROVIDERS } from '../constants/providers'
import { Connection, ConnectionType } from './types'

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`)
}

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  (actions) => new Network({ actions, urlMap: RPC_PROVIDERS, defaultChainId: 42161 })
)
export const networkConnection: Connection = {
  getName: () => 'Network',
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
  shouldDisplay: () => false,
}

export const connections = [networkConnection]

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = connections.find((connection) => connection.connector === c)
    if (!connection) {
      throw Error('unsupported connector')
    }
    return connection
  } else {
    switch (c) {
      case ConnectionType.NETWORK:
        return networkConnection
      default:
        return networkConnection
    }
  }
}
