import { getCurrentEpoch, getEpochEndTimestamp, getEpochStartTimestamp } from 'utils/epochManagement'
import gql from 'graphql-tag'
import { useActiveNetworkVersion, useClients } from 'state/application/hooks'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { AssociatedGaugeBribingDetails, AssociatedGaugeRewardDistributionDetails } from 'types/gauges'
import { useAvailableProtocolTokenData } from 'state/tokens/hooks'
export const GET_GAUGES_WITH_REWARDS_FOR_EPOCH = (epoch: number) => {
  const epochStart = getEpochStartTimestamp(epoch)
  const epochEnd = getEpochEndTimestamp(epoch)

  const queryString = `
  query gaugesWithRewardsForEpoch {
    gaugeRewardAddeds(where: {timestamp_gte: ${epochStart}, timestamp_lte: ${epochEnd}}) {
      id
      timestamp
      amount
      gauge {
        id
        poolAddress
      }
    }
  }
  `

  return gql(queryString)
}

export const GET_GAUGE_BRIBING_DETAILS_FOR_CURRENT_EPOCH = () => {
  const currentEpoch = getCurrentEpoch()
  const epochStartTimestamp = getEpochStartTimestamp(currentEpoch)
  const epochEndTimestamp = getEpochEndTimestamp(currentEpoch)

  const queryString = `
  query FetchRewardsForEpoch {
    rewards(where: {timestamp_gt: "${epochStartTimestamp}", timestamp_lt: "${epochEndTimestamp}"}) {
      id
      tokenAmount
      tokenAddress
      gauge {
        id
        poolAddress
      }
    }
  }
  `

  return gql(queryString)
}

interface GaugesWithRewardsForEpochResponse {
  gaugeRewardAddeds: {
    id: string
    timestamp: number
    amount: number
    gauge: {
      id: string
      poolAddress: string
    }
  }[]
}

interface GaugesBribingDetailsResponse {
  rewards: {
    id: string
    tokenAmount: number
    tokenAddress: string
    gauge: {
      id: string
      poolAddress: string
    }
  }[]
}

export const useGaugeAddressesWithRewardsOfEpoch = (epochNumber: number) => {
  // const { currentNetwork } = useActiveNetworkVersion()

  const { maNFTV2Client, maNFTV3Client } = useClients()

  const {
    loading: loadingV2,
    error: errorV2,
    data: dataV2,
  } = useQuery<GaugesWithRewardsForEpochResponse>(GET_GAUGES_WITH_REWARDS_FOR_EPOCH(epochNumber), {
    client: maNFTV2Client,
    fetchPolicy: 'cache-first',
  })

  const {
    loading: loadingV3,
    error: errorV3,
    data: dataV3,
  } = useQuery<GaugesWithRewardsForEpochResponse>(GET_GAUGES_WITH_REWARDS_FOR_EPOCH(epochNumber), {
    client: maNFTV3Client,
    fetchPolicy: 'cache-first',
  })

  const joinedData = useMemo(() => {
    if (dataV2 && dataV3) {
      return [...dataV2.gaugeRewardAddeds, ...dataV3.gaugeRewardAddeds]
    } else {
      return undefined
    }
  }, [dataV2, dataV3])

  const joinedLoading = useMemo(() => {
    return loadingV2 || loadingV3
  }, [loadingV2, loadingV3])

  const joinedError = useMemo(() => {
    return errorV2 || errorV3
  }, [errorV2, errorV3])

  const formattedData = useMemo(() => {
    if (joinedData) {
      return joinedData.map((p) => {
        return p.gauge.id
      })
    } else {
      return undefined
    }
  }, [joinedData])

  return {
    loading: joinedLoading,
    error: Boolean(joinedError),
    addresses: formattedData,
  }
}

export const useGaugeRewardDistributionPerEpoch = (epochNumber: number) => {
  // const { currentNetwork } = useActiveNetworkVersion()

  const { maNFTV2Client, maNFTV3Client } = useClients()

  const {
    loading: loadingV2,
    error: errorV2,
    data: dataV2,
  } = useQuery<GaugesWithRewardsForEpochResponse>(GET_GAUGES_WITH_REWARDS_FOR_EPOCH(epochNumber), {
    client: maNFTV2Client,
    fetchPolicy: 'cache-first',
  })

  const {
    loading: loadingV3,
    error: errorV3,
    data: dataV3,
  } = useQuery<GaugesWithRewardsForEpochResponse>(GET_GAUGES_WITH_REWARDS_FOR_EPOCH(epochNumber), {
    client: maNFTV3Client,
    fetchPolicy: 'cache-first',
  })

  const joinedData = useMemo(() => {
    if (dataV2 && dataV3) {
      return [...dataV2.gaugeRewardAddeds, ...dataV3.gaugeRewardAddeds]
    } else {
      return undefined
    }
  }, [dataV2, dataV3])

  const joinedLoading = useMemo(() => {
    return loadingV2 || loadingV3
  }, [loadingV2, loadingV3])

  const joinedError = useMemo(() => {
    return errorV2 || errorV3
  }, [errorV2, errorV3])

  const formattedData = useMemo(() => {
    if (joinedData) {
      return joinedData.map((p) => {
        return {
          id: p.gauge.id,
          amount: p.amount,
          poolAddress: p.gauge.poolAddress,
        }
      })
    } else {
      return undefined
    }
  }, [joinedData])

  return {
    loading: joinedLoading,
    error: Boolean(joinedError),
    distribution: formattedData,
    totalAmount:
      joinedData?.reduce((accum, current) => {
        return accum + current.amount
      }, 0) || 0,
  }
}

export const useGaugeBribingDetailsForCurrentEpoch = () => {
  const { rewardsV3Client } = useClients()
  const { loading, error, data } = useQuery<GaugesBribingDetailsResponse>(
    GET_GAUGE_BRIBING_DETAILS_FOR_CURRENT_EPOCH(),
    {
      client: rewardsV3Client,
      fetchPolicy: 'cache-first',
    }
  )

  const formattedData = useMemo(() => {
    if (data) {
      return data.rewards.map((p) => {
        return {
          gaugeId: p.gauge.id,
          amount: p.tokenAmount,
          tokenAddress: p.tokenAddress,
          poolAddress: p.gauge.poolAddress,
        }
      })
    } else {
      return undefined
    }
  }, [data])

  const [availableProtocolTokenData, setAvailableProtocolTokenData] = useAvailableProtocolTokenData()

  const groupedDataByGaugeId = useMemo(() => {
    if (!availableProtocolTokenData) return undefined
    if (formattedData) {
      return formattedData.reduce((accum: { [gaugeId: string]: AssociatedGaugeBribingDetails }, current) => {
        const token = availableProtocolTokenData.tokensDictionary[current.tokenAddress.toLowerCase()]
        const tokenPrice = Number(token.price_quote)
        const tokenDecimals = token.decimals
        const tokenAmount = Number(current.amount) / tokenDecimals
        const tokenToUSD = tokenAmount * tokenPrice

        if (accum[current.gaugeId]) {
          accum[current.gaugeId].bribeUSDAmount += tokenToUSD
        } else {
          accum[current.gaugeId] = {
            gaugeAddress: current.gaugeId,
            bribeUSDAmount: tokenToUSD,
            poolAddress: current.poolAddress,
          }
        }
        return accum
      }, {})
    } else {
      return undefined
    }
  }, [formattedData, availableProtocolTokenData])

  return groupedDataByGaugeId
}
