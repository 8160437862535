import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
import { TokenList } from 'utils/getChronosTokenList'

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const updateVersion = createAction<void>('global/updateVersion')

export const fetchTokenList: Readonly<{
  pending: ActionCreatorWithPayload<{ requestId: string }>
  fulfilled: ActionCreatorWithPayload<{ tokenList: TokenList; requestId: string }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestId: string }>
}> = {
  pending: createAction('global/fetchTokenList/pending'),
  fulfilled: createAction('global/fetchTokenList/fulfilled'),
  rejected: createAction('global/fetchTokenList/rejected'),
}

// versioning
export const acceptTokensUpdate = createAction('global/acceptTokensUpdate')
