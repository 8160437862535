import { getPastEpochs } from 'utils/epochManagement'

export const voteAnalyticsMockData = [
  {
    voteDollarValue: 0.31081494038405544,
    pair: {
      pair_address: '0x9DeBDCcA77DDF6f89A048bD8E1DD6270B82fe8e1',
      symbol: 'vAMM-wUSDR/USDC',
      name: 'VolatileV1 AMM - wUSDR/USDC',
      decimals: '18',
      stable: false,
      total_supply: '23238967179433',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x9483ab65847A447e36d21af1CaB8C87e9712ff93',
      token0_symbol: 'wUSDR',
      token0_decimals: '9',
      reserve0: '708878911832303',
      claimable0: '0',
      token1: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '761836170748',
      claimable1: '0',
      gauge: '0xf78e178D089ED3EF3f297Aac0bEcF9d57f931227',
      gauge_total_supply: '23238967178433',
      gauge_total_weight: '29152026555848364991028764672212',
      fee: '0xA80aeE0d537FAc8e7AEd64f3C9d66a5A12E470Ff',
      bribe: '0xCeA39c01009e4AC3C42C21fEbc846e0A0B3987DD',
      emissions: '581939931719871038',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '1465724118008579205541615',
    voteWeightPercentage: {
      _value: '249473387518995952',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x9DeBDCcA77DDF6f89A048bD8E1DD6270B82fe8e1',
      _gauge: '0xf78e178D089ED3EF3f297Aac0bEcF9d57f931227',
      _externalBribeAddress: '0xCeA39c01009e4AC3C42C21fEbc846e0A0B3987DD',
      _internalBribeAddress: '0xA80aeE0d537FAc8e7AEd64f3C9d66a5A12E470Ff',
      totalVotesOnGauge: '1465724118008579205541615',
      totalVotesOnGaugeByUser: '9504229122883314872996',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xa80aee0d537fac8e7aed64f3c9d66a5a12e470ff',
        tokens: ['0xaf88d065e77c8cc2239327c5edb3a432268e5831', '0x9483ab65847a447e36d21af1cab8c87e9712ff93'],
        symbols: ['USDC', 'wUSDRv3'],
        decimals: ['6', '9'],
        amounts: [45438638, 45052663876],
      },
    },
    poolAPR: 20.60384125557914,
    dollarRewardsValue: {
      _value: '93947266372000000000',
      _decimals: 18,
    },
    yourVoteWeight: '9504229122883314872996',
    yourVoteWeightPercentage: {
      _value: '200047750053375805',
      _decimals: 18,
    },
    token0Symbol: 'wUSDRv3',
    token1Symbol: 'USDC',
  },
  {
    voteDollarValue: 20.19411907159798,
    pair: {
      pair_address: '0xAa02446c845859Fc0D967f62964A703EdDA160c2',
      symbol: 'vAMM-WETH/DEUS',
      name: 'VolatileV1 AMM - WETH/DEUS',
      decimals: '18',
      stable: false,
      total_supply: '674496027766922402685',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '90225638385262006832',
      claimable0: '0',
      token1: '0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
      token1_symbol: 'DEUS',
      token1_decimals: '18',
      reserve1: '5056388165569586230515',
      claimable1: '0',
      gauge: '0xDB17De10b0AfDf67ecC79636b7d3FEB0Ea230EED',
      gauge_total_supply: '673619181576560263018',
      gauge_total_weight: '1283335788023778883878589914628116002175',
      fee: '0xeB2834099ca4921016240Db1aa32E8Cf3e6c291A',
      bribe: '0x8ebBcECA0750d871bfE4605c7149EE4e286EDDB6',
      emissions: '185592697926840989',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '938691711807612673801473',
    voteWeightPercentage: {
      _value: '159769903697033609',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xAa02446c845859Fc0D967f62964A703EdDA160c2',
      _gauge: '0xDB17De10b0AfDf67ecC79636b7d3FEB0Ea230EED',
      _externalBribeAddress: '0x8ebBcECA0750d871bfE4605c7149EE4e286EDDB6',
      _internalBribeAddress: '0xeB2834099ca4921016240Db1aa32E8Cf3e6c291A',
      totalVotesOnGauge: '938691711807612673801473',
      totalVotesOnGaugeByUser: '8668862659568070942446',
      externalBribeReward: {
        bribe: '0x8ebbceca0750d871bfe4605c7149ee4e286eddb6',
        tokens: ['0xde5ed76e7c05ec5e4572cfc88d1acea165109e44'],
        symbols: ['DEUS'],
        decimals: ['18'],
        amounts: [130000000000000000000],
      },
      internalBribeReward: {
        bribe: '0xeb2834099ca4921016240db1aa32e8cf3e6c291a',
        tokens: ['0xde5ed76e7c05ec5e4572cfc88d1acea165109e44', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['DEUS', 'WETH'],
        decimals: ['18', '18'],
        amounts: [1887245107943389700, 38788601740751850],
      },
    },
    poolAPR: 1341.1494662331554,
    dollarRewardsValue: {
      _value: '3916371512543507431610',
      _decimals: 18,
    },
    yourVoteWeight: '8668862659568070942446',
    yourVoteWeightPercentage: {
      _value: '182464716301179917',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'DEUS',
  },
  {
    voteDollarValue: 6.762936211706402,
    pair: {
      pair_address: '0xfef1e3e114E9D73FA794ee3B8435c59Aef33a0f8',
      symbol: 'vAMM-fBOMB/YFX',
      name: 'VolatileV1 AMM - fBOMB/YFX',
      decimals: '18',
      stable: false,
      total_supply: '2701845652822476936661967',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x74ccbe53F77b08632ce0CB91D3A545bF6B8E0979',
      token0_symbol: 'fBOMB',
      token0_decimals: '18',
      reserve0: '41979771056910921173279575',
      claimable0: '0',
      token1: '0xaaE0c3856e665ff9b3E2872B6D75939D810b7E40',
      token1_symbol: 'YFX',
      token1_decimals: '18',
      reserve1: '173892561771700248191292',
      claimable1: '0',
      gauge: '0xd93Fc5A699E32c92E421A3339Ad8b92912E63963',
      gauge_total_supply: '2688340148338109404749017',
      gauge_total_weight: '5892216324904361824927059533863531798182395',
      fee: '0x002F80f61A77EC8E85785c85ccB3be9325947eD5',
      bribe: '0x1F3A5a58AD87B84CacE474c74eF43aF009B8543f',
      emissions: '100441279071900551',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '1646532494864950828',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '860299534004776718503342',
    voteWeightPercentage: {
      _value: '146427173021334611',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xfef1e3e114E9D73FA794ee3B8435c59Aef33a0f8',
      _gauge: '0xd93Fc5A699E32c92E421A3339Ad8b92912E63963',
      _externalBribeAddress: '0x1F3A5a58AD87B84CacE474c74eF43aF009B8543f',
      _internalBribeAddress: '0x002F80f61A77EC8E85785c85ccB3be9325947eD5',
      totalVotesOnGauge: '860299534004776718503342',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        bribe: '0x1f3a5a58ad87b84cace474c74ef43af009b8543f',
        tokens: ['0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979'],
        symbols: ['fBOMB'],
        decimals: ['18'],
        amounts: [3e23],
      },
      internalBribeReward: {
        bribe: '0x002f80f61a77ec8e85785c85ccb3be9325947ed5',
        tokens: ['0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979'],
        symbols: ['fBOMB'],
        decimals: ['18'],
        amounts: [4.429286348021778e21],
      },
    },
    poolAPR: 449.3570576763544,
    dollarRewardsValue: {
      _value: '1202610450145808222180',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'fBOMB',
    token1Symbol: 'YFX',
  },
  {
    voteDollarValue: 21.714501382016337,
    pair: {
      pair_address: '0x9a56F822bAbFbfDC2F25Cb34eB952ff38Af869d8',
      symbol: 'WETH/USDC',
      name: 'Dyson ChrV3 WETH/USDC.e 0.05% Vault',
      decimals: '18',
      stable: false,
      total_supply: '8552828670367385',
      clPool: '0xDbd06e0F411Ca93f80a34bB3cadbe91521A48bF2',
      dysonPool: '0x9a56F822bAbFbfDC2F25Cb34eB952ff38Af869d8',
      feeAmount: '500',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '188355117008914785332',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '173115538535',
      claimable1: '0',
      gauge: '0x39B2299BAC951b3Bbdd5bfEFe5fFa5AA888A704f',
      gauge_total_supply: '8539868132186217',
      gauge_total_weight: '10330223805253534326033921067056067',
      fee: '0x7956BBc2134Fb9c53EB6Ea6A7277eF52b6E0f85f',
      bribe: '0xe30D4f9CE58d935C3AC8819C96055B8C0957B467',
      emissions: '796466672169950094',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x45d4D227863371b94Bcb94e1375B604bc51cfD26',
      _a0Expect: '188352457710528105655',
      _a1Expect: '173491412068',
    },
    voteWeight: '540719079592872213709203',
    voteWeightPercentage: {
      _value: '92033022329921063',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x9a56F822bAbFbfDC2F25Cb34eB952ff38Af869d8',
      _gauge: '0x39B2299BAC951b3Bbdd5bfEFe5fFa5AA888A704f',
      _externalBribeAddress: '0xe30D4f9CE58d935C3AC8819C96055B8C0957B467',
      _internalBribeAddress: '0x7956BBc2134Fb9c53EB6Ea6A7277eF52b6E0f85f',
      totalVotesOnGauge: '540719079592872213709203',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x7956bbc2134fb9c53eb6ea6a7277ef52b6e0f85f',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['USDC.e', 'WETH'],
        decimals: ['6', '18'],
        amounts: [1180453065, 765717289734721900],
      },
    },
    poolAPR: 1447.5954138310942,
    dollarRewardsValue: {
      _value: '2435020440674054253001',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 0.2750741525527979,
    pair: {
      pair_address: '0x3fb69D8720816A604487F2Fd5813b72C15Dd77Ea',
      symbol: 'vAMM-plsRDNT/RDNT',
      name: 'VolatileV1 AMM - plsRDNT/RDNT',
      decimals: '18',
      stable: false,
      total_supply: '192571610383905718583182',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x1605bbDAB3b38d10fA23A7Ed0d0e8F4FEa5bFF59',
      token0_symbol: 'plsRDNT',
      token0_decimals: '18',
      reserve0: '119348192393539628795915',
      claimable0: '0',
      token1: '0x3082CC23568eA640225c2467653dB90e9250AaA0',
      token1_symbol: 'RDNT',
      token1_decimals: '18',
      reserve1: '310719622308755933109229',
      claimable1: '0',
      gauge: '0x9285994Fa369F44894c8680e28eC534d61D4C1A3',
      gauge_total_supply: '185975597462482914534621',
      gauge_total_weight: '400821932242635683802682976086344080593020',
      fee: '0xF095b0135a1bC8CaF1070DE1152937fAD76CF342',
      bribe: '0xD9e185f3edbd3E1fFA57c56751086E43B25EE5F6',
      emissions: '91914242818102573',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '1208348672881233221',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '440859592001716833625396',
    voteWeightPercentage: {
      _value: '75036450915701585',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x3fb69D8720816A604487F2Fd5813b72C15Dd77Ea',
      _gauge: '0x9285994Fa369F44894c8680e28eC534d61D4C1A3',
      _externalBribeAddress: '0xD9e185f3edbd3E1fFA57c56751086E43B25EE5F6',
      _internalBribeAddress: '0xF095b0135a1bC8CaF1070DE1152937fAD76CF342',
      totalVotesOnGauge: '440859592001716833625396',
      totalVotesOnGaugeByUser: '19683280248223420058216',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xf095b0135a1bc8caf1070de1152937fad76cf342',
        tokens: ['0x3082cc23568ea640225c2467653db90e9250aaa0', '0x1605bbdab3b38d10fa23a7ed0d0e8f4fea5bff59'],
        symbols: ['RDNT', 'plsRDNT'],
        decimals: ['18', '18'],
        amounts: [62263741323129184000, 18232715884452830000],
      },
    },
    poolAPR: 18.374836374751744,
    dollarRewardsValue: {
      _value: '25200403426873442355',
      _decimals: 18,
    },
    yourVoteWeight: '19683280248223420058216',
    yourVoteWeightPercentage: {
      _value: '414299347839437623',
      _decimals: 18,
    },
    token0Symbol: 'plsRDNT',
    token1Symbol: 'RDNT',
  },
  {
    voteDollarValue: 24.994751752241843,
    pair: {
      pair_address: '0xCD7E3BDbe5F4053f0c9e51013887abA8cCEc239C',
      symbol: 'WETH/USDT',
      name: 'Dyson ChrV3 WETH/USDT 0.05% Vault',
      decimals: '18',
      stable: false,
      total_supply: '81129384692025215',
      clPool: '0x6604CD7858d836854Ef63A8633BB051D2A4F67C6',
      dysonPool: '0xCD7E3BDbe5F4053f0c9e51013887abA8cCEc239C',
      feeAmount: '500',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '69928052833510708602',
      claimable0: '0',
      token1: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      token1_symbol: 'USDT',
      token1_decimals: '6',
      reserve1: '143546009488',
      claimable1: '0',
      gauge: '0x28BD7fd472662a37C759Fc7BD58E0ac2CC96397d',
      gauge_total_supply: '81083611895026627',
      gauge_total_weight: '97041986433433304145837198043579305',
      fee: '0x1582c47Cf5a3b36D47411Afd61acF9B741411554',
      bribe: '0xCf12dCD08A245aC6113F08E245D7E03FcA0bb0e1',
      emissions: '449232186276983623',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0xbd9C12E5E2Aa7311D8E738B9ce0dffE8483eBD34',
      _a0Expect: '69920511392247645168',
      _a1Expect: '147191579115',
    },
    voteWeight: '230009078144040705575889',
    voteWeightPercentage: {
      _value: '39148665959510004',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xCD7E3BDbe5F4053f0c9e51013887abA8cCEc239C',
      _gauge: '0x28BD7fd472662a37C759Fc7BD58E0ac2CC96397d',
      _externalBribeAddress: '0xCf12dCD08A245aC6113F08E245D7E03FcA0bb0e1',
      _internalBribeAddress: '0x1582c47Cf5a3b36D47411Afd61acF9B741411554',
      totalVotesOnGauge: '230009078144040705575889',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x1582c47cf5a3b36d47411afd61acf9b741411554',
        tokens: ['0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['USDT', 'WETH'],
        decimals: ['6', '18'],
        amounts: [552790574, 399170481162959600],
      },
    },
    poolAPR: 1686.3456315752412,
    dollarRewardsValue: {
      _value: '1206633247905868463153',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'USDT',
  },
  {
    voteDollarValue: 16.577407007473266,
    pair: {
      pair_address: '0x37A7bF05807feCD6b1CCE53366059e70E313e4Af',
      symbol: 'sAMM-FRAX/MAI',
      name: 'StableV1 AMM - FRAX/MAI',
      decimals: '18',
      stable: true,
      total_supply: '38025992113013363803133',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
      token0_symbol: 'FRAX',
      token0_decimals: '18',
      reserve0: '27008685848558017689333',
      claimable0: '0',
      token1: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
      token1_symbol: 'MAI',
      token1_decimals: '18',
      reserve1: '49180680277355297365283',
      claimable1: '0',
      gauge: '0xEA0234515c5D9f8Bf7d43bbA1176aC0c1ECF584f',
      gauge_total_supply: '37636961044861299726248',
      gauge_total_weight: '49790542677738388685715441404625654176101',
      fee: '0xCB6a9DeCF476f00b75784888269992983d308Eab',
      bribe: '0x5f1Ed6928F7EBfa9af9CFDFCf290a12Fe22c11b2',
      emissions: '35074277161179010',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '87761570929119556',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '227826166892014959554285',
    voteWeightPercentage: {
      _value: '38777123826849942',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x37A7bF05807feCD6b1CCE53366059e70E313e4Af',
      _gauge: '0xEA0234515c5D9f8Bf7d43bbA1176aC0c1ECF584f',
      _externalBribeAddress: '0x5f1Ed6928F7EBfa9af9CFDFCf290a12Fe22c11b2',
      _internalBribeAddress: '0xCB6a9DeCF476f00b75784888269992983d308Eab',
      totalVotesOnGauge: '227826166892014959554285',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        bribe: '0x5f1ed6928f7ebfa9af9cfdfcf290a12fe22c11b2',
        tokens: ['0xb9c8f0d3254007ee4b98970b94544e473cd610ec'],
        symbols: ['QiDao'],
        decimals: ['18'],
        amounts: [2.5e22],
      },
      internalBribeReward: {
        bribe: '0xcb6a9decf476f00b75784888269992983d308eab',
        tokens: ['0x3f56e0c36d275367b8c502090edf38289b3dea0d', '0x17fc002b466eec40dae837fc4be5c67993ddbd6f'],
        symbols: ['MAI', 'FRAX'],
        decimals: ['18', '18'],
        amounts: [1391814678206859800, 1195461870021764600],
      },
    },
    poolAPR: 1118.6662938492236,
    dollarRewardsValue: {
      _value: '792844238461000004905',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'FRAX',
    token1Symbol: 'MAI',
  },
  {
    voteDollarValue: 7.269882621693672,
    pair: {
      pair_address: '0xd306c21Da241fB0eE03C658Ceaa75daa8fE78658',
      symbol: 'vAMM-IBEX/WETH',
      name: 'VolatileV1 AMM - IBEX/WETH',
      decimals: '18',
      stable: false,
      total_supply: '7013952834929104681831',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x56659245931CB6920e39C189D2a0e7DD0dA2d57b',
      token0_symbol: 'IBEX',
      token0_decimals: '18',
      reserve0: '1643056823160754342678243',
      claimable0: '70410455637675820',
      token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token1_symbol: 'WETH',
      token1_decimals: '18',
      reserve1: '29941596238802293558',
      claimable1: '1345003004358',
      gauge: '0x1D6Ed6BA2E36A466974F23dc6C7803306E76E325',
      gauge_total_supply: '5282202824040738379454',
      gauge_total_weight: '7480745770056802164139690221632552926710',
      fee: '0x1D393a30B1Ef62ef0996Ae75D361E238e0ca972A',
      bribe: '0xad3c10e1623975D9d27B2B250DFF2365a9a6F6fC',
      emissions: '20432323116731340',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '302021296584059384',
      account_token0_balance: '678214201108135432',
      account_token1_balance: '2444886394425129',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '197981299044008780105020',
    voteWeightPercentage: {
      _value: '33697381881814083',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xd306c21Da241fB0eE03C658Ceaa75daa8fE78658',
      _gauge: '0x1D6Ed6BA2E36A466974F23dc6C7803306E76E325',
      _externalBribeAddress: '0xad3c10e1623975D9d27B2B250DFF2365a9a6F6fC',
      _internalBribeAddress: '0x1D393a30B1Ef62ef0996Ae75D361E238e0ca972A',
      totalVotesOnGauge: '197981299044008780105020',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        bribe: '0xad3c10e1623975d9d27b2b250dff2365a9a6f6fc',
        tokens: ['0x56659245931cb6920e39c189d2a0e7dd0da2d57b'],
        symbols: ['IBEX'],
        decimals: ['18'],
        amounts: [1e22],
      },
      internalBribeReward: {
        bribe: '0x1d393a30b1ef62ef0996ae75d361e238e0ca972a',
        tokens: ['0x82af49447d8a07e3bd95bd0d56f35241523fbab1', '0x56659245931cb6920e39c189d2a0e7dd0da2d57b'],
        symbols: ['WETH', 'IBEX'],
        decimals: ['18', '18'],
        amounts: [1517934910797949, 80402737043112820000],
      },
    },
    poolAPR: 492.1279791594438,
    dollarRewardsValue: {
      _value: '303100005955210942734',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'IBEX',
    token1Symbol: 'WETH',
  },
  {
    voteDollarValue: 7.470771559969966,
    pair: {
      pair_address: '0xeee5FBcFF2f22b351D0A693ED62C515C01ef3EcA',
      symbol: 'vAMM-IBEX/USDC',
      name: 'VolatileV1 AMM - IBEX/USDC',
      decimals: '18',
      stable: false,
      total_supply: '178899502967864805',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x56659245931CB6920e39C189D2a0e7DD0dA2d57b',
      token0_symbol: 'IBEX',
      token0_decimals: '18',
      reserve0: '1038668075139856199608250',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '30813821227',
      claimable1: '0',
      gauge: '0x7d5D79aB51e62DA3493eF13Ac514443441bB4b22',
      gauge_total_supply: '128644474109949275',
      gauge_total_weight: '182150252585510892289990502680237715',
      fee: '0x8CBa75Fb1631AF89D3cf04651f904b15188D264B',
      bribe: '0x983D36ac1a5c4689E8963125e1318A18344d2644',
      emissions: '19588252299145306',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '678214201108135432',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '192955215576013220236949',
    voteWeightPercentage: {
      _value: '32841917982906788',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xeee5FBcFF2f22b351D0A693ED62C515C01ef3EcA',
      _gauge: '0x7d5D79aB51e62DA3493eF13Ac514443441bB4b22',
      _externalBribeAddress: '0x983D36ac1a5c4689E8963125e1318A18344d2644',
      _internalBribeAddress: '0x8CBa75Fb1631AF89D3cf04651f904b15188D264B',
      totalVotesOnGauge: '192955215576013220236949',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        bribe: '0x983d36ac1a5c4689e8963125e1318a18344d2644',
        tokens: ['0x56659245931cb6920e39c189d2a0e7dd0da2d57b'],
        symbols: ['IBEX'],
        decimals: ['18'],
        amounts: [1e22],
      },
      internalBribeReward: {
        bribe: '0x8cba75fb1631af89d3cf04651f904b15188d264b',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0x56659245931cb6920e39c189d2a0e7dd0da2d57b'],
        symbols: ['USDC.e', 'IBEX'],
        decimals: ['6', '18'],
        amounts: [1984804, 119291046614203350000],
      },
    },
    poolAPR: 506.0429290154778,
    dollarRewardsValue: {
      _value: '303757913749543255740',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'IBEX',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 0.06952236325432473,
    pair: {
      pair_address: '0x11357D693D8dfdFa570C3b899FBa073152f9dcAC',
      symbol: 'sAMM-jEUR/agEUR',
      name: 'StableV1 AMM - jEUR/agEUR',
      decimals: '18',
      stable: true,
      total_supply: '42615765319511192517025',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0xAD435674417520aeeED6b504bBe654d4f556182F',
      token0_symbol: 'jEUR',
      token0_decimals: '18',
      reserve0: '45813188346149081359040',
      claimable0: '0',
      token1: '0xFA5Ed56A203466CbBC2430a43c66b9D8723528E7',
      token1_symbol: 'agEUR',
      token1_decimals: '18',
      reserve1: '39421874677828637065797',
      claimable1: '0',
      gauge: '0x3B8F1220f2553e306Fe59c8fAcBAA8a52275B8c4',
      gauge_total_supply: '9414937835007928730057',
      gauge_total_weight: '18709473975687856013128148913422943921246',
      fee: '0xC4A2D89d4aB8e052382F850F5DE5634c42A8FDAc',
      bribe: '0x4D9F54f3Bd7cD916b895Ea55A260447Fae10C416',
      emissions: '20329573588633853',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '149572981403633879564425',
    voteWeightPercentage: {
      _value: '25458050320395915',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x11357D693D8dfdFa570C3b899FBa073152f9dcAC',
      _gauge: '0x3B8F1220f2553e306Fe59c8fAcBAA8a52275B8c4',
      _externalBribeAddress: '0x4D9F54f3Bd7cD916b895Ea55A260447Fae10C416',
      _internalBribeAddress: '0xC4A2D89d4aB8e052382F850F5DE5634c42A8FDAc',
      totalVotesOnGauge: '149572981403633879564425',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xc4a2d89d4ab8e052382f850f5de5634c42a8fdac',
        tokens: ['0xfa5ed56a203466cbbc2430a43c66b9d8723528e7', '0xad435674417520aeeed6b504bbe654d4f556182f'],
        symbols: ['agEUR', 'jEUR'],
        decimals: ['18', '18'],
        amounts: [982865522059951900, 1048164276348992600],
      },
    },
    poolAPR: 4.742784913296027,
    dollarRewardsValue: {
      _value: '2206837212897506483',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'jEUR',
    token1Symbol: 'agEUR',
  },
  {
    voteDollarValue: 0.021075107987156377,
    pair: {
      pair_address: '0x31440D82F14e36Be8EDE9E47137E3dfFEEf32C51',
      symbol: 'vAMM-WETH/ACS',
      name: 'VolatileV1 AMM - WETH/ACS',
      decimals: '18',
      stable: false,
      total_supply: '236941063419204029496',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '4462973827781402876',
      claimable0: '0',
      token1: '0x8888888888f004100C0353d657BE6300587A6CcD',
      token1_symbol: 'ACS',
      token1_decimals: '18',
      reserve1: '12579295711920331242992',
      claimable1: '0',
      gauge: '0x42df709F0B0922695630d496A98607634Fc49F43',
      gauge_total_supply: '235889873617122597582',
      gauge_total_weight: '302644052406937885201743601384655723150',
      fee: '0x699f352875FC20d68e882f80b56917D2C5Fae283',
      bribe: '0x73a78fb85c9517068ee597C8470BdC807fd80A15',
      emissions: '0',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '139296530156012118701915',
    voteWeightPercentage: {
      _value: '23708948239779808',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x31440D82F14e36Be8EDE9E47137E3dfFEEf32C51',
      _gauge: '0x42df709F0B0922695630d496A98607634Fc49F43',
      _externalBribeAddress: '0x73a78fb85c9517068ee597C8470BdC807fd80A15',
      _internalBribeAddress: '0x699f352875FC20d68e882f80b56917D2C5Fae283',
      totalVotesOnGauge: '139296530156012118701915',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x699f352875fc20d68e882f80b56917d2c5fae283',
        tokens: ['0x8888888888f004100c0353d657be6300587a6ccd', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['ACS', 'WETH'],
        decimals: ['18', '18'],
        amounts: [836207115725787300, 84495315185714],
      },
    },
    poolAPR: 1.4410760145106623,
    dollarRewardsValue: {
      _value: '624469032708867740',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'ACS',
  },
  {
    voteDollarValue: 0,
    pair: {
      pair_address: '0x323f89F0426D70eB5DA9D381873146f1BFecb8aA',
      symbol: 'vAMM-pxGLP/WETH',
      name: 'VolatileV1 AMM - pxGLP/WETH',
      decimals: '18',
      stable: false,
      total_supply: '2460633114444716124',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x0eac365E4d7DE0E293078bd771BA7D0ba9a4c892',
      token0_symbol: 'pxGLP',
      token0_decimals: '18',
      reserve0: '106911976841093726344',
      claimable0: '0',
      token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token1_symbol: 'WETH',
      token1_decimals: '18',
      reserve1: '56632713216978462',
      claimable1: '0',
      gauge: '0xe156268b9BD2da09c438Bb80AE87FA3f0D129317',
      gauge_total_supply: '23961113804598056',
      gauge_total_weight: '50526031029339436719157710837277952',
      fee: '0xa889aC3bDB4e7595575c807E7D9f649f3965DD1f',
      bribe: '0x414b930f9Be6af3FBA64891e8e88da2072Ef6c8d',
      emissions: '115342724906447',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '2444886394425129',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '134449840236396499014984',
    voteWeightPercentage: {
      _value: '22884017997011161',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x323f89F0426D70eB5DA9D381873146f1BFecb8aA',
      _gauge: '0xe156268b9BD2da09c438Bb80AE87FA3f0D129317',
      _externalBribeAddress: '0x414b930f9Be6af3FBA64891e8e88da2072Ef6c8d',
      _internalBribeAddress: '0xa889aC3bDB4e7595575c807E7D9f649f3965DD1f',
      totalVotesOnGauge: '134449840236396499014984',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
    },
    poolAPR: 0,
    dollarRewardsValue: {
      _value: '0',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'pxGLP',
    token1Symbol: 'WETH',
  },
  {
    voteDollarValue: 6.824476062923599,
    pair: {
      pair_address: '0xa636E51C966331411Bb8D5f83D06010C3197f12D',
      symbol: 'vAMM-IBEX/ARB',
      name: 'VolatileV1 AMM - IBEX/ARB',
      decimals: '18',
      stable: false,
      total_supply: '385838362021503144512670',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x56659245931CB6920e39C189D2a0e7DD0dA2d57b',
      token0_symbol: 'IBEX',
      token0_decimals: '18',
      reserve0: '2194058803766468298082078',
      claimable0: '5182123202005524',
      token1: '0x912CE59144191C1204E64559FE8253a0e49E6548',
      token1_symbol: 'ARB',
      token1_decimals: '18',
      reserve1: '67853116347884522521881',
      claimable1: '202226432986875',
      gauge: '0x31eF10d38Ec3a73Ee28b97Bb7690B29137C2E82f',
      gauge_total_supply: '362024653250061747259422',
      gauge_total_weight: '469195566899180133429306787002527701406674',
      fee: '0xc2f73Bc8E1900A4e317898CBde415ac23E3aDE03',
      bribe: '0xE60C0460F713339B0487a195c0a87f920F66cFb1',
      emissions: '9699517360356831',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '4061612522700426588',
      account_token0_balance: '678214201108135432',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '97430580319604872472240',
    voteWeightPercentage: {
      _value: '16583159560270793',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xa636E51C966331411Bb8D5f83D06010C3197f12D',
      _gauge: '0x31eF10d38Ec3a73Ee28b97Bb7690B29137C2E82f',
      _externalBribeAddress: '0xE60C0460F713339B0487a195c0a87f920F66cFb1',
      _internalBribeAddress: '0xc2f73Bc8E1900A4e317898CBde415ac23E3aDE03',
      totalVotesOnGauge: '97430580319604872472240',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        bribe: '0xe60c0460f713339b0487a195c0a87f920f66cfb1',
        tokens: ['0x56659245931cb6920e39c189d2a0e7dd0da2d57b'],
        symbols: ['IBEX'],
        decimals: ['18'],
        amounts: [4.503e21],
      },
      internalBribeReward: {
        bribe: '0xc2f73bc8e1900a4e317898cbde415ac23e3ade03',
        tokens: ['0x912ce59144191c1204e64559fe8253a0e49e6548', '0x56659245931cb6920e39c189d2a0e7dd0da2d57b'],
        symbols: ['ARB', 'IBEX'],
        decimals: ['18', '18'],
        amounts: [5625573591799910000, 126555632455594380000],
      },
    },
    poolAPR: 473.4119930290612,
    dollarRewardsValue: {
      _value: '143488885911826590673',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'IBEX',
    token1Symbol: 'ARB',
  },
  {
    voteDollarValue: 1.6010121908428496,
    pair: {
      pair_address: '0x9aC09266B68a8Fea081C232e54fA31526E740570',
      symbol: 'vAMM-RDNT/WETH',
      name: 'VolatileV1 AMM - RDNT/WETH',
      decimals: '18',
      stable: false,
      total_supply: '1055779731336297993726',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x3082CC23568eA640225c2467653dB90e9250AaA0',
      token0_symbol: 'RDNT',
      token0_decimals: '18',
      reserve0: '89128022308090239741672',
      claimable0: '0',
      token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token1_symbol: 'WETH',
      token1_decimals: '18',
      reserve1: '12507280348534791174',
      claimable1: '0',
      gauge: '0xA85883177C9579b5Fa864e34539093678f1Ca3a9',
      gauge_total_supply: '255878818412415750576',
      gauge_total_weight: '482110487795221540972746847283580380593',
      fee: '0x3DB370bD55F86a49fd4Fa238Bed8a077F36D21C4',
      bribe: '0x6f212ED46487BC73EcfdC4e949466142a4842411',
      emissions: '16841417475294836',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '2444886394425129',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '74304865629160514230489',
    voteWeightPercentage: {
      _value: '12647050225820178',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x9aC09266B68a8Fea081C232e54fA31526E740570',
      _gauge: '0xA85883177C9579b5Fa864e34539093678f1Ca3a9',
      _externalBribeAddress: '0x6f212ED46487BC73EcfdC4e949466142a4842411',
      _internalBribeAddress: '0x3DB370bD55F86a49fd4Fa238Bed8a077F36D21C4',
      totalVotesOnGauge: '74304865629160514230489',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x3db370bd55f86a49fd4fa238bed8a077f36d21c4',
        tokens: ['0x82af49447d8a07e3bd95bd0d56f35241523fbab1', '0x3082cc23568ea640225c2467653db90e9250aaa0'],
        symbols: ['WETH', 'RDNT'],
        decimals: ['18', '18'],
        amounts: [8096351733802530, 55696439046507500000],
      },
    },
    poolAPR: 112.70573594092276,
    dollarRewardsValue: {
      _value: '26052355206004950110',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'RDNT',
    token1Symbol: 'WETH',
  },
  {
    voteDollarValue: 110.60058011186878,
    pair: {
      pair_address: '0x9093351Ff2857fEA881808bDFF0CbEA73586F59F',
      symbol: 'vAMM-WETH/DCA',
      name: 'VolatileV1 AMM - WETH/DCA',
      decimals: '18',
      stable: false,
      total_supply: '6293888778050047164229',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '3541519314888020561',
      claimable0: '0',
      token1: '0x965F298E4ade51C0b0bB24e3369deB6C7D5b3951',
      token1_symbol: 'DCA',
      token1_decimals: '18',
      reserve1: '11185342259976800899304973',
      claimable1: '0',
      gauge: '0x8a8986D2Eb37B5fA071059b9E5bC862B94834A12',
      gauge_total_supply: '6277048767741034866000',
      gauge_total_weight: '12669451033711721362477743614089239502000',
      fee: '0xd7c2283869696F470F62727FD5e44B5ca343D65d',
      bribe: '0x754b53f752137e188F6Bb1dC6cE572B47c57fd01',
      emissions: '49961218311420326',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '43004214882039824715355',
    voteWeightPercentage: {
      _value: '7319526937165741',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x9093351Ff2857fEA881808bDFF0CbEA73586F59F',
      _gauge: '0x8a8986D2Eb37B5fA071059b9E5bC862B94834A12',
      _externalBribeAddress: '0x754b53f752137e188F6Bb1dC6cE572B47c57fd01',
      _internalBribeAddress: '0xd7c2283869696F470F62727FD5e44B5ca343D65d',
      totalVotesOnGauge: '43004214882039824715355',
      totalVotesOnGaugeByUser: '4747565456250616701771',
      externalBribeReward: {
        bribe: '0x754b53f752137e188f6bb1dc6ce572b47c57fd01',
        tokens: ['0xe950a64c7d2e2495f8d48f9cc47ad833457998ad'],
        symbols: ['xDCA'],
        decimals: ['18'],
        amounts: [2e24],
      },
      internalBribeReward: {
        bribe: '0xd7c2283869696f470f62727fd5e44b5ca343d65d',
        tokens: ['0x965f298e4ade51c0b0bb24e3369deb6c7d5b3951', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['DCA', 'WETH'],
        decimals: ['18', '18'],
        amounts: [682127099818872900000, 117727266463717],
      },
    },
    poolAPR: 8134.155486284788,
    dollarRewardsValue: {
      _value: '1088196216416677576818',
      _decimals: 18,
    },
    yourVoteWeight: '4747565456250616701771',
    yourVoteWeightPercentage: {
      _value: '99928124151318880',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'DCA',
  },
  {
    voteDollarValue: 24.247811729366603,
    pair: {
      pair_address: '0x951966d474f13260f90a3E770b53589013d49241',
      symbol: 'WETH/ARB',
      name: 'Dyson ChrV3 WETH/ARB 0.05% Vault',
      decimals: '18',
      stable: false,
      total_supply: '1179113570',
      clPool: '0x97B582BdA7D1F8b2ec11211d24a9dC86Cc944B41',
      dysonPool: '0x951966d474f13260f90a3E770b53589013d49241',
      feeAmount: '500',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '4802601718415486168',
      claimable0: '0',
      token1: '0x912CE59144191C1204E64559FE8253a0e49E6548',
      token1_symbol: 'ARB',
      token1_decimals: '18',
      reserve1: '20790734462272420350614',
      claimable1: '0',
      gauge: '0x491fCe3A8Fa6B12Aae626D0033B77C59def910c3',
      gauge_total_supply: '1171987931',
      gauge_total_weight: '1490677608624590092916888355',
      fee: '0x4992Ee4c8e0f607ACBA37CbeD8B55BbeA2fe352C',
      bribe: '0xd34aE12990C7b68EC75BF7689C48027dC1Bc033e',
      emissions: '1232139463164994',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x5aC9dA0a19b8333f871590956FC1Af07fee99B84',
      _a0Expect: '5038680603561613093',
      _a1Expect: '20789374018285216564490',
    },
    voteWeight: '27282973822211909750649',
    voteWeightPercentage: {
      _value: '4643695097455887',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x951966d474f13260f90a3E770b53589013d49241',
      _gauge: '0x491fCe3A8Fa6B12Aae626D0033B77C59def910c3',
      _externalBribeAddress: '0xd34aE12990C7b68EC75BF7689C48027dC1Bc033e',
      _internalBribeAddress: '0x4992Ee4c8e0f607ACBA37CbeD8B55BbeA2fe352C',
      totalVotesOnGauge: '27282973822211909750649',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x4992ee4c8e0f607acba37cbed8b55bbea2fe352c',
        tokens: ['0x912ce59144191c1204e64559fe8253a0e49e6548', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['ARB', 'WETH'],
        decimals: ['18', '18'],
        amounts: [84682925113704900000, 47920647668723256],
      },
    },
    poolAPR: 1887.7546261818572,
    dollarRewardsValue: {
      _value: '160221564472448199645',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'ARB',
  },
  {
    voteDollarValue: 27.09053506030124,
    pair: {
      pair_address: '0x2e62d340CdC21848d444d6b454F89a541F9969d6',
      symbol: 'ARB/USDC',
      name: 'Dyson ChrV3 ARB/USDC.e 0.05% Vault',
      decimals: '18',
      stable: false,
      total_supply: '29132683592872017',
      clPool: '0x0d85a74F29237EdC0422e6DD2da4B694Cb566e68',
      dysonPool: '0x2e62d340CdC21848d444d6b454F89a541F9969d6',
      feeAmount: '500',
      token0: '0x912CE59144191C1204E64559FE8253a0e49E6548',
      token0_symbol: 'ARB',
      token0_decimals: '18',
      reserve0: '16381109392879497477233',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '10311566280',
      claimable1: '0',
      gauge: '0x80763CDbae59f32427E00bd118FdaF733A3Dc5f0',
      gauge_total_supply: '28954705089533570',
      gauge_total_weight: '35935802591512305659877173875412013',
      fee: '0xF109dC2B6bcb8be0C33d8c74E154a09023845e42',
      bribe: '0x102d352C45E9C88Fc5e8a051842E2177Fcf2C977',
      emissions: '1448253689648504',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x91e459499E9aC62B0Eb5cCF20B15EB9FB9B160Bb',
      _a0Expect: '16531785769430089174783',
      _a1Expect: '10311507638',
    },
    voteWeight: '26156750012743067738099',
    voteWeightPercentage: {
      _value: '4452006316872490',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x2e62d340CdC21848d444d6b454F89a541F9969d6',
      _gauge: '0x80763CDbae59f32427E00bd118FdaF733A3Dc5f0',
      _externalBribeAddress: '0x102d352C45E9C88Fc5e8a051842E2177Fcf2C977',
      _internalBribeAddress: '0xF109dC2B6bcb8be0C33d8c74E154a09023845e42',
      totalVotesOnGauge: '26156750012743067738099',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xf109dc2b6bcb8be0c33d8c74e154a09023845e42',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0x912ce59144191c1204e64559fe8253a0e49e6548'],
        symbols: ['USDC.e', 'ARB'],
        decimals: ['6', '18'],
        amounts: [82945054, 93043035181230240000],
      },
    },
    poolAPR: 2122.8110113262865,
    dollarRewardsValue: {
      _value: '172734398475309941224',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'ARB',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 0.0004938378616279265,
    pair: {
      pair_address: '0xD284707D48B2C020C5A438c81966a71D787F0575',
      symbol: 'sAMM-CHR/USDC',
      name: 'StableV1 AMM - CHR/USDC',
      decimals: '18',
      stable: true,
      total_supply: '7958932335002494',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      token0_symbol: 'CHR',
      token0_decimals: '18',
      reserve0: '35245862243233812578705',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '189696886',
      claimable1: '0',
      gauge: '0x78E50A6ECd89DD4747D51998cB1645c7E27Dd8e9',
      gauge_total_supply: '4473483920540823',
      gauge_total_weight: '4473483920540823000000000000000000',
      fee: '0x2E89F6103f1be37DB0e05F3722103D54e65A152F',
      bribe: '0x498F7438B0110eB94F61946C27427586ad3D5bE3',
      emissions: '1122140239421114',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '8321846',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '11923117108383390060431',
    voteWeightPercentage: {
      _value: '2029372634500573',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xD284707D48B2C020C5A438c81966a71D787F0575',
      _gauge: '0x78E50A6ECd89DD4747D51998cB1645c7E27Dd8e9',
      _externalBribeAddress: '0x498F7438B0110eB94F61946C27427586ad3D5bE3',
      _internalBribeAddress: '0x2E89F6103f1be37DB0e05F3722103D54e65A152F',
      totalVotesOnGauge: '11923117108383390060431',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x2e89f6103f1be37db0e05f3722103d54e65a152f',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0x15b2fb8f08e4ac1ce019eadae02ee92aedf06851'],
        symbols: ['USDC.e', 'CHR'],
        decimals: ['6', '18'],
        amounts: [555, 70895910916379590],
      },
    },
    poolAPR: 0.04594214853615912,
    dollarRewardsValue: {
      _value: '1704059793971618',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'CHR',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 0.055767986296274226,
    pair: {
      pair_address: '0xB260163158311596Ea88a700C5a30f101D072326',
      symbol: 'sAMM-USD+/DAI+',
      name: 'StableV1 AMM - USD+/DAI+',
      decimals: '18',
      stable: true,
      total_supply: '1437551423413485851',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
      token0_symbol: 'USD+',
      token0_decimals: '6',
      reserve0: '1340387607455',
      claimable0: '0',
      token1: '0xeb8E93A0c7504Bffd8A8fFa56CD754c63aAeBFe8',
      token1_symbol: 'DAI+',
      token1_decimals: '18',
      reserve1: '1534765135468848681478591',
      claimable1: '0',
      gauge: '0xbaf72cD5D730561B65EA677a3C9E8028A9809913',
      gauge_total_supply: '1418647190850226136',
      gauge_total_weight: '3224627916241732145194990969567105876',
      fee: '0xFCb251DA3E50D01dCa4142bD2c9f5CA12E77fd92',
      bribe: '0x094450577096533Cf9ab63096b881D5075DcF878',
      emissions: '201316442161451820',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '3255645',
      account_token1_balance: '103619638404344',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '11867143169384811651128',
    voteWeightPercentage: {
      _value: '2019845597315888',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xB260163158311596Ea88a700C5a30f101D072326',
      _gauge: '0xbaf72cD5D730561B65EA677a3C9E8028A9809913',
      _externalBribeAddress: '0x094450577096533Cf9ab63096b881D5075DcF878',
      _internalBribeAddress: '0xFCb251DA3E50D01dCa4142bD2c9f5CA12E77fd92',
      totalVotesOnGauge: '11867143169384811651128',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xfcb251da3e50d01dca4142bd2c9f5ca12e77fd92',
        tokens: ['0xeb8e93a0c7504bffd8a8ffa56cd754c63aaebfe8', '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65'],
        symbols: ['DAI+', 'USD+'],
        decimals: ['18', '6'],
        amounts: [96736609535411890, 95072],
      },
    },
    poolAPR: 5.195234034270679,
    dollarRewardsValue: {
      _value: '191794000000000000',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'USD+',
    token1Symbol: 'DAI+',
  },
  {
    voteDollarValue: 0.19554647214285664,
    pair: {
      pair_address: '0xd36b843E8Efced2CbCab007B5efdcb01891DF331',
      symbol: 'vAMM-fBOMB/WETH',
      name: 'VolatileV1 AMM - fBOMB/WETH',
      decimals: '18',
      stable: false,
      total_supply: '1250529435749274030321',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x74ccbe53F77b08632ce0CB91D3A545bF6B8E0979',
      token0_symbol: 'fBOMB',
      token0_decimals: '18',
      reserve0: '813609897825380906608804',
      claimable0: '0',
      token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token1_symbol: 'WETH',
      token1_decimals: '18',
      reserve1: '1980720931146091562',
      claimable1: '0',
      gauge: '0x1689E40FD2bBd7C6604Be431fAE18ca7E3EB244D',
      gauge_total_supply: '255077750831856582791',
      gauge_total_weight: '523809863923397161945936732424549245017',
      fee: '0x1610baC6BcA0DF0e442f88b5f6c1239C67CC55dE',
      bribe: '0xc4479f19802F9e972f1CdD0019830bDdB8f26D10',
      emissions: '0',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '2444886394425129',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '10591217345905987364920',
    voteWeightPercentage: {
      _value: '1802676804433704',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xd36b843E8Efced2CbCab007B5efdcb01891DF331',
      _gauge: '0x1689E40FD2bBd7C6604Be431fAE18ca7E3EB244D',
      _externalBribeAddress: '0xc4479f19802F9e972f1CdD0019830bDdB8f26D10',
      _internalBribeAddress: '0x1610baC6BcA0DF0e442f88b5f6c1239C67CC55dE',
      totalVotesOnGauge: '10591217345905987364920',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x1610bac6bca0df0e442f88b5f6c1239c67cc55de',
        tokens: ['0x82af49447d8a07e3bd95bd0d56f35241523fbab1', '0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979'],
        symbols: ['WETH', 'fBOMB'],
        decimals: ['18', '18'],
        amounts: [177253664454222, 83748898592460310000],
      },
    },
    poolAPR: 18.854834132031403,
    dollarRewardsValue: {
      _value: '621229849748186420',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'fBOMB',
    token1Symbol: 'WETH',
  },
  {
    voteDollarValue: 0.015302103501237122,
    pair: {
      pair_address: '0x8424F9015353b713D9c19ac3A729ef427ff37def',
      symbol: 'vAMM-POLY/USDC',
      name: 'VolatileV1 AMM - POLY/USDC',
      decimals: '18',
      stable: false,
      total_supply: '16526434972815459',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x34772C4D12F288368Aa35AE7bc527A6B2b3e8906',
      token0_symbol: 'POLY',
      token0_decimals: '18',
      reserve0: '217597331717676678926675',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '1255176777',
      claimable1: '0',
      gauge: '0x8b2E5f25B751EfE05060A36450Ed072b7Cdfd63d',
      gauge_total_supply: '2659431301977721',
      gauge_total_weight: '4141146469526221873021706094066837',
      fee: '0xd0602Cf3b1d6231412Ef0CA916fD3CEA9EBf083f',
      bribe: '0x1F2eeEcE6DeAB8c3A2c811cc20d2eA7606faF941',
      emissions: '0',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '7711065902390503232589',
    voteWeightPercentage: {
      _value: '1312460993454378',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x8424F9015353b713D9c19ac3A729ef427ff37def',
      _gauge: '0x8b2E5f25B751EfE05060A36450Ed072b7Cdfd63d',
      _externalBribeAddress: '0x1F2eeEcE6DeAB8c3A2c811cc20d2eA7606faF941',
      _internalBribeAddress: '0xd0602Cf3b1d6231412Ef0CA916fD3CEA9EBf083f',
      totalVotesOnGauge: '7711065902390503232589',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xd0602cf3b1d6231412ef0ca916fd3cea9ebf083f',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0x34772c4d12f288368aa35ae7bc527a6b2b3e8906'],
        symbols: ['USDC.e', 'POLY'],
        decimals: ['6', '18'],
        amounts: [762, 6724416700753053000],
      },
    },
    poolAPR: 1.6489170856096143,
    dollarRewardsValue: {
      _value: '39554595844903458',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'POLY',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 1.1413449231957176,
    pair: {
      pair_address: '0xA2F1C1B52E1b7223825552343297Dc68a29ABecC',
      symbol: 'vAMM-WETH/USDC',
      name: 'VolatileV1 AMM - WETH/USDC',
      decimals: '18',
      stable: false,
      total_supply: '18168350358264284',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '449169445167125514565',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '735732737174',
      claimable1: '0',
      gauge: '0x4244Bf8F64500279d41840971A52408972265E48',
      gauge_total_supply: '46678456822576',
      gauge_total_weight: '100079141077844128274575157760849',
      fee: '0x753e4Ed0424eD4Ed43d067870763a1F11b49A565',
      bribe: '0x39A6a984d34b1E506918B7376A052daAF7F5EA31',
      emissions: '1537431588823912',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '7472070210631845442475',
    voteWeightPercentage: {
      _value: '1271782762064909',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xA2F1C1B52E1b7223825552343297Dc68a29ABecC',
      _gauge: '0x4244Bf8F64500279d41840971A52408972265E48',
      _externalBribeAddress: '0x39A6a984d34b1E506918B7376A052daAF7F5EA31',
      _internalBribeAddress: '0x753e4Ed0424eD4Ed43d067870763a1F11b49A565',
      totalVotesOnGauge: '7472070210631845442475',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x753e4ed0424ed4ed43d067870763a1f11b49a565',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['USDC.e', 'WETH'],
        decimals: ['6', '18'],
        amounts: [1386259, 920371367717400],
      },
    },
    poolAPR: 124.51036153911178,
    dollarRewardsValue: {
      _value: '2894210779625659873',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 29.04179078477368,
    pair: {
      pair_address: '0x393c93f8133bc89db3177D6e10182Efd4129D0d1',
      symbol: 'vAMM-WETH/LQTY',
      name: 'VolatileV1 AMM - WETH/LQTY',
      decimals: '18',
      stable: false,
      total_supply: '1019464338032706963167',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '22286743746531346238',
      claimable0: '0',
      token1: '0xfb9E5D956D889D91a82737B9bFCDaC1DCE3e1449',
      token1_symbol: 'LQTY',
      token1_decimals: '18',
      reserve1: '46633441984676805422562',
      claimable1: '0',
      gauge: '0x6C6aBaDd312D8eb546d4e4b0F0cb939Ed0D735cc',
      gauge_total_supply: '843299718522656023524',
      gauge_total_weight: '1675281096229169393027361637590805560347',
      fee: '0x705C73130E19dA2E52e2A235C04E93Fc5C926cD8',
      bribe: '0x180d232b434ed93D5618d28Ba1558000C33d2F5C',
      emissions: '13135717243961511',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '5378128044232818644170',
    voteWeightPercentage: {
      _value: '915383601334600',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x393c93f8133bc89db3177D6e10182Efd4129D0d1',
      _gauge: '0x6C6aBaDd312D8eb546d4e4b0F0cb939Ed0D735cc',
      _externalBribeAddress: '0x180d232b434ed93D5618d28Ba1558000C33d2F5C',
      _internalBribeAddress: '0x705C73130E19dA2E52e2A235C04E93Fc5C926cD8',
      totalVotesOnGauge: '5378128044232818644170',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x705c73130e19da2e52e2a235c04e93fc5c926cd8',
        tokens: ['0xfb9e5d956d889d91a82737b9bfcdac1dce3e1449', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['LQTY', 'WETH'],
        decimals: ['18', '18'],
        amounts: [41336006979916920000, 17618775757900984],
      },
    },
    poolAPR: 3654.6388725522447,
    dollarRewardsValue: {
      _value: '61144771396380397583',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'LQTY',
  },
  {
    voteDollarValue: 3.3950204041295358,
    pair: {
      pair_address: '0x45C633700854177c803bce394CA69694B7465BCb',
      symbol: 'vAMM-KUJI/WETH',
      name: 'VolatileV1 AMM - KUJI/WETH',
      decimals: '18',
      stable: false,
      total_supply: '226250034063339',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x3A18dcC9745eDcD1Ef33ecB93b0b6eBA5671e7Ca',
      token0_symbol: 'KUJI',
      token0_decimals: '6',
      reserve0: '10726109699',
      claimable0: '0',
      token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token1_symbol: 'WETH',
      token1_decimals: '18',
      reserve1: '4772382900014248498',
      claimable1: '0',
      gauge: '0x0E80C685F8b081610018D7fe3Da9932bAE5f4f94',
      gauge_total_supply: '224444830465145',
      gauge_total_weight: '445749146646900349565232514794627',
      fee: '0x9D0E4A5C60aa7A7423D53b8705e2920359a6B86B',
      bribe: '0xdEB59c9Fe809B613e8a05aE4E185E1ba8bBEEdB3',
      emissions: '10615130021347581',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '2444886394425129',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '4399135416310845922583',
    voteWeightPercentage: {
      _value: '748754285324130',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x45C633700854177c803bce394CA69694B7465BCb',
      _gauge: '0x0E80C685F8b081610018D7fe3Da9932bAE5f4f94',
      _externalBribeAddress: '0xdEB59c9Fe809B613e8a05aE4E185E1ba8bBEEdB3',
      _internalBribeAddress: '0x9D0E4A5C60aa7A7423D53b8705e2920359a6B86B',
      totalVotesOnGauge: '4399135416310845922583',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x9d0e4a5c60aa7a7423d53b8705e2920359a6b86b',
        tokens: ['0x82af49447d8a07e3bd95bd0d56f35241523fbab1', '0x3a18dcc9745edcd1ef33ecb93b0b6eba5671e7ca'],
        symbols: ['WETH', 'KUJI'],
        decimals: ['18', '6'],
        amounts: [1906848979505448, 4603391],
      },
    },
    poolAPR: 472.39020832835246,
    dollarRewardsValue: {
      _value: '6464753000000000000',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'KUJI',
    token1Symbol: 'WETH',
  },
  {
    voteDollarValue: 26.64179140815416,
    pair: {
      pair_address: '0xc5b7AcC5Dc13679eb180702a1C40df1664b3078B',
      symbol: 'vAMM-WETH/LUSD',
      name: 'VolatileV1 AMM - WETH/LUSD',
      decimals: '18',
      stable: false,
      total_supply: '966455570406177441913',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '23803954989703122732',
      claimable0: '0',
      token1: '0x93b346b6BC2548dA6A1E7d98E9a421B42541425b',
      token1_symbol: 'LUSD',
      token1_decimals: '18',
      reserve1: '39238706511754450865782',
      claimable1: '0',
      gauge: '0xA288E0D3D918Fee350441DCE416caEC03099C689',
      gauge_total_supply: '966000000000000000000',
      gauge_total_weight: '2038761261574074071832000000000000000000',
      fee: '0x69dc84AD8BfC749af938F52F14308c7C28a33A01',
      bribe: '0x5916Cfc4A395A9207efD15eD7b287325181af0e1',
      emissions: '12167948926404198',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '4083063473519311601104',
    voteWeightPercentage: {
      _value: '694957300407864',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xc5b7AcC5Dc13679eb180702a1C40df1664b3078B',
      _gauge: '0xA288E0D3D918Fee350441DCE416caEC03099C689',
      _externalBribeAddress: '0x5916Cfc4A395A9207efD15eD7b287325181af0e1',
      _internalBribeAddress: '0x69dc84AD8BfC749af938F52F14308c7C28a33A01',
      totalVotesOnGauge: '4083063473519311601104',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x69dc84ad8bfc749af938f52f14308c7c28a33a01',
        tokens: ['0x93b346b6bc2548da6a1e7d98e9a421b42541425b', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['LUSD', 'WETH'],
        decimals: ['18', '18'],
        amounts: [23481004778338170000, 15630957500199342],
      },
    },
    poolAPR: 3857.6939625267896,
    dollarRewardsValue: {
      _value: '49000173884128743676',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'LUSD',
  },
  {
    voteDollarValue: 23.69674562670432,
    pair: {
      pair_address: '0x20585BFBC272A9D58ad17582BCdA9A5A57271d6A',
      symbol: 'vAMM-CHR/USDC',
      name: 'VolatileV1 AMM - CHR/USDC',
      decimals: '18',
      stable: false,
      total_supply: '14119934939',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      token0_symbol: 'CHR',
      token0_decimals: '18',
      reserve0: '8925398151058589780216039',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '144555411399',
      claimable1: '0',
      gauge: '0x600aA4393dC7118473651ACf3a471dcE847bfC35',
      gauge_total_supply: '13543983984',
      gauge_total_weight: '28869487939525024072976210537',
      fee: '0x754e715e1af454516eA7CbFeD52F89ce2dAA4615',
      bribe: '0x1862Bcc5e1214Aa6606dAaB4C02f81d320A81195',
      emissions: '3460707818997391',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '8321846',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '4005954519060092171415',
    voteWeightPercentage: {
      _value: '681832980598537',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x20585BFBC272A9D58ad17582BCdA9A5A57271d6A',
      _gauge: '0x600aA4393dC7118473651ACf3a471dcE847bfC35',
      _externalBribeAddress: '0x1862Bcc5e1214Aa6606dAaB4C02f81d320A81195',
      _internalBribeAddress: '0x754e715e1af454516eA7CbFeD52F89ce2dAA4615',
      totalVotesOnGauge: '4005954519060092171415',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x754e715e1af454516ea7cbfed52f89ce2daa4615',
        tokens: ['0x15b2fb8f08e4ac1ce019eadae02ee92aedf06851', '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8'],
        symbols: ['CHR', 'USDC.e'],
        decimals: ['18', '6'],
        amounts: [1.47263037277745e21, 19338221],
      },
    },
    poolAPR: 3467.16565753915,
    dollarRewardsValue: {
      _value: '43208016000000000000',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'CHR',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 25.930532162865568,
    pair: {
      pair_address: '0x9195c50426A601868Dcf405aF4fa9452E49e36cD',
      symbol: 'vAMM-ERN/USDC',
      name: 'VolatileV1 AMM - ERN/USDC',
      decimals: '18',
      stable: false,
      total_supply: '17384974773953356',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0xa334884bF6b0A066d553D19e507315E839409e62',
      token0_symbol: 'ERN',
      token0_decimals: '18',
      reserve0: '23483996243531563709655',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '24197040037',
      claimable1: '0',
      gauge: '0x95d7ca6D9eA0515F59d424f21872e0ab051DD5CA',
      gauge_total_supply: '16613346302562957',
      gauge_total_weight: '32466253377328146644379055273407011',
      fee: '0xcf4423e378cB7BF2eE948DF327d60Dc5817Ba1dB',
      bribe: '0xEc93d4c51835e6E89760746E64b3595Ca373aA23',
      emissions: '254512070047627',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '295698582686349487',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '3320334237562437838589',
    voteWeightPercentage: {
      _value: '565137067585017',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x9195c50426A601868Dcf405aF4fa9452E49e36cD',
      _gauge: '0x95d7ca6D9eA0515F59d424f21872e0ab051DD5CA',
      _externalBribeAddress: '0xEc93d4c51835e6E89760746E64b3595Ca373aA23',
      _internalBribeAddress: '0xcf4423e378cB7BF2eE948DF327d60Dc5817Ba1dB',
      totalVotesOnGauge: '3320334237562437838589',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xcf4423e378cb7bf2ee948df327d60dc5817ba1db',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0xa334884bf6b0a066d553d19e507315e839409e62'],
        symbols: ['USDC.e', 'ERN'],
        decimals: ['6', '18'],
        amounts: [21597220, 21357234692765647000],
      },
    },
    poolAPR: 4223.658542387973,
    dollarRewardsValue: {
      _value: '43626896845738775323',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'ERN',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 0.2533014567208001,
    pair: {
      pair_address: '0x01b325acD3170A90ca8399441B7FBD8727598008',
      symbol: 'vAMM-GND/gmUSD',
      name: 'VolatileV1 AMM - GND/gmUSD',
      decimals: '18',
      stable: false,
      total_supply: '2427944568794706122230',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0xD67A097dCE9d4474737e6871684aE3c05460F571',
      token0_symbol: 'GND',
      token0_decimals: '18',
      reserve0: '206075824039463911704',
      claimable0: '0',
      token1: '0xEC13336bbd50790a00CDc0fEddF11287eaF92529',
      token1_symbol: 'gmUSD',
      token1_decimals: '18',
      reserve1: '28606005728052800868465',
      claimable1: '0',
      gauge: '0x487aAB733ea4F586BB5272548f493e475dc9DBf9',
      gauge_total_supply: '139467926465579258760',
      gauge_total_weight: '274605639055815092184140444531452212600',
      fee: '0x650e9bfFf8426F71a3baAE6a5604a57c5E63a33F',
      bribe: '0x33DA786dcFcE92BBa34b9e79Cd0E15235DE10CA2',
      emissions: '162126285480237',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '6695281788334',
      account_token1_balance: '25897475118959054',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '1866483934075301124676',
    voteWeightPercentage: {
      _value: '317684661159967',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x01b325acD3170A90ca8399441B7FBD8727598008',
      _gauge: '0x487aAB733ea4F586BB5272548f493e475dc9DBf9',
      _externalBribeAddress: '0x33DA786dcFcE92BBa34b9e79Cd0E15235DE10CA2',
      _internalBribeAddress: '0x650e9bfFf8426F71a3baAE6a5604a57c5E63a33F',
      totalVotesOnGauge: '1866483934075301124676',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x650e9bfff8426f71a3baae6a5604a57c5e63a33f',
        tokens: ['0xec13336bbd50790a00cdc0feddf11287eaf92529', '0xd67a097dce9d4474737e6871684ae3c05460f571'],
        symbols: ['gmUSD', 'GND'],
        decimals: ['18', '18'],
        amounts: [84397648516547420, 1758360705525406],
      },
    },
    poolAPR: 60.36015988979624,
    dollarRewardsValue: {
      _value: '350476058513928898',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'GND',
    token1Symbol: 'gmUSD',
  },
  {
    voteDollarValue: 10.72121648678971,
    pair: {
      pair_address: '0x6522d9AD280ed92155b33a6c4dDE84FCc857914d',
      symbol: 'vAMM-IDIA/USDC',
      name: 'VolatileV1 AMM - IDIA/USDC',
      decimals: '18',
      stable: false,
      total_supply: '591531867830175372',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0xE6045890B20945D00e6f3c01878265C03C5435D3',
      token0_symbol: 'IDIA',
      token0_decimals: '18',
      reserve0: '4966609866319304047857362',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '70452528360',
      claimable1: '0',
      gauge: '0xB48E04392d85720AAf2a8950A09FfEE5558b86Fe',
      gauge_total_supply: '589875202289548510',
      gauge_total_weight: '1186832726428225852856926752460438480',
      fee: '0xE4ad0Bf7d567b6C9745CdB00A0ffe0b0D3030AA1',
      bribe: '0x6dDF944ed164b19A7d4c17BC4F879D0C86432CF2',
      emissions: '263850787254274371',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '1385691390545272354738',
    voteWeightPercentage: {
      _value: '235851427296506',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x6522d9AD280ed92155b33a6c4dDE84FCc857914d',
      _gauge: '0xB48E04392d85720AAf2a8950A09FfEE5558b86Fe',
      _externalBribeAddress: '0x6dDF944ed164b19A7d4c17BC4F879D0C86432CF2',
      _internalBribeAddress: '0xE4ad0Bf7d567b6C9745CdB00A0ffe0b0D3030AA1',
      totalVotesOnGauge: '1385691390545272354738',
      totalVotesOnGaugeByUser: '4905865149432351493183',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xe4ad0bf7d567b6c9745cdb00a0ffe0b0d3030aa1',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0xe6045890b20945d00e6f3c01878265c03c5435d3'],
        symbols: ['USDC.e', 'IDIA'],
        decimals: ['6', '18'],
        amounts: [5812067, 561067902537954300000],
      },
    },
    poolAPR: 3195.458668104885,
    dollarRewardsValue: {
      _value: '13774740997069148121',
      _decimals: 18,
    },
    yourVoteWeight: '4905865149432351493183',
    yourVoteWeightPercentage: {
      _value: '103260061654687773',
      _decimals: 18,
    },
    token0Symbol: 'IDIA',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 0.308418340079514,
    pair: {
      pair_address: '0xfd1e3458C7a1D3506f5cC6180A53F1e60f9D6BEa',
      symbol: 'sAMM-DAI/USDC',
      name: 'StableV1 AMM - DAI/USDC',
      decimals: '18',
      stable: true,
      total_supply: '11425964',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      token0_symbol: 'DAI',
      token0_decimals: '18',
      reserve0: '12403328823466486721988',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '11458998417',
      claimable1: '0',
      gauge: '0x9Cc47da251C0b3B1bb5E39b90a4E3c30AB33cA13',
      gauge_total_supply: '6735847',
      gauge_total_weight: '12534210664641203675814976',
      fee: '0xeBA9b134d3e2c4f1955e7c51D220EA9435715875',
      bribe: '0x05Af93718973166ED269cec71C21dFf15EA97764',
      emissions: '0',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '1272438337904952169457',
    voteWeightPercentage: {
      _value: '216575205842612',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xfd1e3458C7a1D3506f5cC6180A53F1e60f9D6BEa',
      _gauge: '0x9Cc47da251C0b3B1bb5E39b90a4E3c30AB33cA13',
      _externalBribeAddress: '0x05Af93718973166ED269cec71C21dFf15EA97764',
      _internalBribeAddress: '0xeBA9b134d3e2c4f1955e7c51D220EA9435715875',
      totalVotesOnGauge: '1272438337904952169457',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0xeba9b134d3e2c4f1955e7c51d220ea9435715875',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1'],
        symbols: ['USDC.e', 'DAI'],
        decimals: ['6', '18'],
        amounts: [168010, 221032160013321020],
      },
    },
    poolAPR: 98.29209006685674,
    dollarRewardsValue: {
      _value: '389080113723480053',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'DAI',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 4.8205454970378465,
    pair: {
      pair_address: '0xafE909b1A5ed90d36f9eE1490fCB855645C00EB3',
      symbol: 'vAMM-WETH/ARB',
      name: 'VolatileV1 AMM - WETH/ARB',
      decimals: '18',
      stable: false,
      total_supply: '2472109343267402994175',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '60018796921866731464',
      claimable0: '1759089466',
      token1: '0x912CE59144191C1204E64559FE8253a0e49E6548',
      token1_symbol: 'ARB',
      token1_decimals: '18',
      reserve1: '101839421323038923908912',
      claimable1: '3595551596438',
      gauge: '0x6CC06AA6e37d2C3a157D0536f4bb8b3dfeC4A47d',
      gauge_total_supply: '325454156060818364354',
      gauge_total_weight: '690245854548001621601124216441694368117',
      fee: '0x36569FDE710b5EbA09bdd3350Ca6772a3da1844A',
      bribe: '0x7A3c3688f1C42985679078874C733946141d298b',
      emissions: '174855849130345',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '18800926162271383',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '1057500420618373871251',
    voteWeightPercentage: {
      _value: '179991724904457',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xafE909b1A5ed90d36f9eE1490fCB855645C00EB3',
      _gauge: '0x6CC06AA6e37d2C3a157D0536f4bb8b3dfeC4A47d',
      _externalBribeAddress: '0x7A3c3688f1C42985679078874C733946141d298b',
      _internalBribeAddress: '0x36569FDE710b5EbA09bdd3350Ca6772a3da1844A',
      totalVotesOnGauge: '1057500420618373871251',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x36569fde710b5eba09bdd3350ca6772a3da1844a',
        tokens: ['0x912ce59144191c1204e64559fe8253a0e49e6548', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['ARB', 'WETH'],
        decimals: ['18', '18'],
        amounts: [3181230840564718600, 1708264178190073],
      },
    },
    poolAPR: 1783.8136108661424,
    dollarRewardsValue: {
      _value: '5868319361173301332',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'ARB',
  },
  {
    voteDollarValue: 3.430709320633898,
    pair: {
      pair_address: '0x498D4C5910f8384a4d8F200D0339016Ea1EBaB32',
      symbol: 'vAMM-WETH/ARKEN',
      name: 'VolatileV1 AMM - WETH/ARKEN',
      decimals: '18',
      stable: false,
      total_supply: '12516444115766178960436',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '13444922510202523651',
      claimable0: '0',
      token1: '0xAf5db6E1CC585ca312E8c8F7c499033590cf5C98',
      token1_symbol: 'ARKEN',
      token1_decimals: '18',
      reserve1: '11652154471234639084349028',
      claimable1: '0',
      gauge: '0xcA221F0E1Ce1d89e26E7558D83F2a71B3C0F59C3',
      gauge_total_supply: '11893990789508682830653',
      gauge_total_weight: '25635792903591221707809206091874216846892',
      fee: '0x96E810F1e8dBA511edDb7EAdDb8D51C5ee8458C1',
      bribe: '0x03245e2381e9B99733D4A05a093Bb56e51d9487E',
      emissions: '289871483040012',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '44427178169160580',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '1010479547184130537690',
    voteWeightPercentage: {
      _value: '171988543108090',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x498D4C5910f8384a4d8F200D0339016Ea1EBaB32',
      _gauge: '0xcA221F0E1Ce1d89e26E7558D83F2a71B3C0F59C3',
      _externalBribeAddress: '0x03245e2381e9B99733D4A05a093Bb56e51d9487E',
      _internalBribeAddress: '0x96E810F1e8dBA511edDb7EAdDb8D51C5ee8458C1',
      totalVotesOnGauge: '1010479547184130537690',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x96e810f1e8dba511eddb7eaddb8d51c5ee8458c1',
        tokens: ['0xaf5db6e1cc585ca312e8c8f7c499033590cf5c98', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['ARKEN', 'WETH'],
        decimals: ['18', '18'],
        amounts: [931173450463544600000, 1460551557610270],
      },
    },
    poolAPR: 1318.040097391876,
    dollarRewardsValue: {
      _value: '4143237879726807867',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'ARKEN',
  },
  {
    voteDollarValue: 8.807180427021931,
    pair: {
      pair_address: '0xd302119B5C46d504D0b3534312e56eC321976251',
      symbol: 'vAMM-ARB/USDC',
      name: 'VolatileV1 AMM - ARB/USDC',
      decimals: '18',
      stable: false,
      total_supply: '31742797554729890',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x912CE59144191C1204E64559FE8253a0e49E6548',
      token0_symbol: 'ARB',
      token0_decimals: '18',
      reserve0: '32353533086416411940532',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '31143765410',
      claimable1: '0',
      gauge: '0x8ec5AFdA3645e0DEf64b1b4eDACaC4db02B040d8',
      gauge_total_supply: '10830084630010445',
      gauge_total_weight: '23649157543548185780645590996487157',
      fee: '0x5a73ad9F774D1449e3FE8340706dcb6f9183Af64',
      bribe: '0x444b30e257880733782C8B9FCCBFFf2eBC052885',
      emissions: '1472528477334126',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '1004201692917588024150',
    voteWeightPercentage: {
      _value: '170920021719254',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xd302119B5C46d504D0b3534312e56eC321976251',
      _gauge: '0x8ec5AFdA3645e0DEf64b1b4eDACaC4db02B040d8',
      _externalBribeAddress: '0x444b30e257880733782C8B9FCCBFFf2eBC052885',
      _internalBribeAddress: '0x5a73ad9F774D1449e3FE8340706dcb6f9183Af64',
      totalVotesOnGauge: '1004201692917588024150',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x5a73ad9f774d1449e3fe8340706dcb6f9183af64',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0x912ce59144191c1204e64559fe8253a0e49e6548'],
        symbols: ['USDC.e', 'ARB'],
        decimals: ['6', '18'],
        amounts: [4814502, 6021098467512481000],
      },
    },
    poolAPR: 3401.1353139057737,
    dollarRewardsValue: {
      _value: '10624991183437724323',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'ARB',
    token1Symbol: 'USDC.e',
  },
  {
    voteDollarValue: 9.959663187141308,
    pair: {
      pair_address: '0xE478af0A1e3cCB4aFce648A9360c7ab730cd2E81',
      symbol: 'vAMM-CHR/WETH',
      name: 'VolatileV1 AMM - CHR/WETH',
      decimals: '18',
      stable: false,
      total_supply: '31564681983492122993',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      token0_symbol: 'CHR',
      token0_decimals: '18',
      reserve0: '1904554626596382257921356',
      claimable0: '0',
      token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token1_symbol: 'WETH',
      token1_decimals: '18',
      reserve1: '18929090064357414503',
      claimable1: '0',
      gauge: '0x02E2Be5350991385940D441bDC0d9AB428D7F133',
      gauge_total_supply: '25294469950635741283',
      gauge_total_weight: '54141885447712038199689198395490836275',
      fee: '0x9E8C30938633901be61f6f1cDD0cDCDaaBa5d656',
      bribe: '0x3e57cEDC17534466506f8e973Ff64218b6014e53',
      emissions: '1354872170258461',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '8321846',
      account_token1_balance: '2444886394425129',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '978704046985255197812',
    voteWeightPercentage: {
      _value: '166580198128753',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xE478af0A1e3cCB4aFce648A9360c7ab730cd2E81',
      _gauge: '0x02E2Be5350991385940D441bDC0d9AB428D7F133',
      _externalBribeAddress: '0x3e57cEDC17534466506f8e973Ff64218b6014e53',
      _internalBribeAddress: '0x9E8C30938633901be61f6f1cDD0cDCDaaBa5d656',
      totalVotesOnGauge: '978704046985255197812',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x9e8c30938633901be61f6f1cdd0cdcdaaba5d656',
        tokens: ['0x82af49447d8a07e3bd95bd0d56f35241523fbab1', '0x15b2fb8f08e4ac1ce019eadae02ee92aedf06851'],
        symbols: ['WETH', 'CHR'],
        decimals: ['18', '18'],
        amounts: [4085130979094447, 325190242847950440000],
      },
    },
    poolAPR: 3929.2576568411587,
    dollarRewardsValue: {
      _value: '11963151725142803532',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'CHR',
    token1Symbol: 'WETH',
  },
  {
    voteDollarValue: 1.9278242551576366,
    pair: {
      pair_address: '0x8a263Cc1DfDCe6c64e2A1cf6133c22eED5D4E29d',
      symbol: 'vAMM-WETH/USDT',
      name: 'VolatileV1 AMM - WETH/USDT',
      decimals: '18',
      stable: false,
      total_supply: '26311423684242',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token0_symbol: 'WETH',
      token0_decimals: '18',
      reserve0: '650888508628769610',
      claimable0: '0',
      token1: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      token1_symbol: 'USDT',
      token1_decimals: '6',
      reserve1: '1064511839',
      claimable1: '0',
      gauge: '0xCB56565faC1a96944466d9651a1306600b74016F',
      gauge_total_supply: '7237902113387',
      gauge_total_weight: '13425633016631167353998653113538',
      fee: '0x9AaEB51DC806c21B835BbF34056b63D5D20Ad34E',
      bribe: '0x8B82083879fb993D02C67a1d81834Df58F5E5CF2',
      emissions: '554253311025812',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '2444886394425129',
      account_token1_balance: '0',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '839042057293871621235',
    voteWeightPercentage: {
      _value: '142809046895129',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x8a263Cc1DfDCe6c64e2A1cf6133c22eED5D4E29d',
      _gauge: '0xCB56565faC1a96944466d9651a1306600b74016F',
      _externalBribeAddress: '0x8B82083879fb993D02C67a1d81834Df58F5E5CF2',
      _internalBribeAddress: '0x9AaEB51DC806c21B835BbF34056b63D5D20Ad34E',
      totalVotesOnGauge: '839042057293871621235',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x9aaeb51dc806c21b835bbf34056b63d5d20ad34e',
        tokens: ['0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9', '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'],
        symbols: ['USDT', 'WETH'],
        decimals: ['6', '18'],
        amounts: [842795, 865335080034990],
      },
    },
    poolAPR: 865.956332874546,
    dollarRewardsValue: {
      _value: '2260286242666189784',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'WETH',
    token1Symbol: 'USDT',
  },
  {
    voteDollarValue: 5.7960067592882165,
    pair: {
      pair_address: '0xdb286ED48b294D348593bFAf1f862393FA8776e9',
      symbol: 'sAMM-frxETH/WETH',
      name: 'StableV1 AMM - frxETH/WETH',
      decimals: '18',
      stable: true,
      total_supply: '61934601823838779445',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x178412e79c25968a32e89b11f63B33F733770c2A',
      token0_symbol: 'frxETH',
      token0_decimals: '18',
      reserve0: '69061186967614413542',
      claimable0: '0',
      token1: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      token1_symbol: 'WETH',
      token1_decimals: '18',
      reserve1: '54813504117545893338',
      claimable1: '0',
      gauge: '0xaDbBDfcEe77CdFd95E991aF152606c27B41F835f',
      gauge_total_supply: '59560584875957792822',
      gauge_total_weight: '130734538012646070853106247552087738454',
      fee: '0x569C96b77a7431A4b6ECae4856f0c807A79B271C',
      bribe: '0x0E0eaf9D49d475685a4Af63A18df1676fb95409e',
      emissions: '6904059888032105',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '2444886394425129',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '836355780378427461477',
    voteWeightPercentage: {
      _value: '142351829473599',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0xdb286ED48b294D348593bFAf1f862393FA8776e9',
      _gauge: '0xaDbBDfcEe77CdFd95E991aF152606c27B41F835f',
      _externalBribeAddress: '0x0E0eaf9D49d475685a4Af63A18df1676fb95409e',
      _internalBribeAddress: '0x569C96b77a7431A4b6ECae4856f0c807A79B271C',
      totalVotesOnGauge: '836355780378427461477',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x569c96b77a7431a4b6ecae4856f0c807a79b271c',
        tokens: ['0x82af49447d8a07e3bd95bd0d56f35241523fbab1', '0x178412e79c25968a32e89b11f63b33f733770c2a'],
        symbols: ['WETH', 'frxETH'],
        decimals: ['18', '18'],
        amounts: [2232367082622692, 1919700023256741],
      },
    },
    poolAPR: 2610.631284727385,
    dollarRewardsValue: {
      _value: '6792354114441976306',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'frxETH',
    token1Symbol: 'WETH',
  },
  {
    voteDollarValue: 2.1251292114038542,
    pair: {
      pair_address: '0x4F7ecB899871114F75c052D94a74ebd316f20660',
      symbol: 'sAMM-LUSD/USDC',
      name: 'StableV1 AMM - LUSD/USDC',
      decimals: '18',
      stable: true,
      total_supply: '19877929819402885',
      clPool: '0x0000000000000000000000000000000000000000',
      dysonPool: '0x0000000000000000000000000000000000000000',
      feeAmount: '0',
      token0: '0x93b346b6BC2548dA6A1E7d98E9a421B42541425b',
      token0_symbol: 'LUSD',
      token0_decimals: '18',
      reserve0: '22348866784250146430101',
      claimable0: '0',
      token1: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      token1_symbol: 'USDC',
      token1_decimals: '6',
      reserve1: '17409664135',
      claimable1: '0',
      gauge: '0x60d997abb4F7f8c622f54F1DC549E7290F18895d',
      gauge_total_supply: '5323097209420769',
      gauge_total_weight: '10803892096412302000079218496351213',
      fee: '0x077784cF460ed2F01a91083954391498Ba57f39D',
      bribe: '0xeFb0712A96123f6E3d722aD10CfB1d501FB49649',
      emissions: '1559993452103102',
      emissions_token: '0x15b2fb8f08E4Ac1Ce019EADAe02eE92AeDF06851',
      emissions_token_decimals: '18',
      account_lp_balance: '0',
      account_token0_balance: '0',
      account_token1_balance: '181004548',
      account_gauge_balance: '0',
      account_gauge_total_weight: '0',
      account_gauge_earned: '0',
      tokens_info_of_account: [],
      dysonStrategy: '0x0000000000000000000000000000000000000000',
      _a0Expect: '0',
      _a1Expect: '0',
    },
    voteWeight: '816477155317253638417',
    voteWeightPercentage: {
      _value: '138968390617473',
      _decimals: 18,
    },
    rewardPair: {
      _pool: '0x4F7ecB899871114F75c052D94a74ebd316f20660',
      _gauge: '0x60d997abb4F7f8c622f54F1DC549E7290F18895d',
      _externalBribeAddress: '0xeFb0712A96123f6E3d722aD10CfB1d501FB49649',
      _internalBribeAddress: '0x077784cF460ed2F01a91083954391498Ba57f39D',
      totalVotesOnGauge: '816477155317253638417',
      totalVotesOnGaugeByUser: '0',
      externalBribeReward: {
        tokens: [],
        symbols: [],
        decimals: [],
        amounts: [],
        bribe: '',
      },
      internalBribeReward: {
        bribe: '0x077784cf460ed2f01a91083954391498ba57f39d',
        tokens: ['0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', '0x93b346b6bc2548da6a1e7d98e9a421b42541425b'],
        symbols: ['USDC.e', 'LUSD'],
        decimals: ['6', '18'],
        amounts: [1234019, 1252352030717309400],
      },
    },
    poolAPR: 977.0847188171357,
    dollarRewardsValue: {
      _value: '2481761125967190608',
      _decimals: 18,
    },
    yourVoteWeight: '0',
    yourVoteWeightPercentage: {
      _value: '0',
      _decimals: 18,
    },
    token0Symbol: 'LUSD',
    token1Symbol: 'USDC.e',
  },
]

export const colors = [
  '#ff2a5f',
  '#b8e062',
  '#31a7c1',
  '#22a968',
  '#38ded4',
  '#4855cc',
  '#ff772a',
  '#de3dbb',
  '#6e7380',
  '#ff5d2b',
  '#5b3193',
  '#f2b701',
  '#24796c',
  '#56569b',
  '#ffabab',
  '#26f291',
]

export const epochsDropdownData = getPastEpochs()
  .map((epoch) => {
    return {
      title: `Epoch ${epoch}`,
      value: epoch,
    }
  })
  .reverse()

export const partnerDropdownData = [
  {
    title: 'Partner 1',
    value: 1,
  },
  {
    title: 'Partner 2',
    value: 2,
  },
]

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length)
  return colors[randomIndex]
}

export default voteAnalyticsMockData
