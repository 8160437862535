import {
  addPoolKeys,
  updatePoolChartData,
  updatePoolTransactions,
  updateTickData,
  updateSelectedPool,
} from 'state/pools/actions'
import { AppState, AppDispatch } from './../index'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PoolData, PoolChartEntry } from './reducer'
import { updatePoolData } from './actions'
import { notEmpty } from 'utils'
import { fetchPoolChartData } from 'data/pools/chartData'
import { Transaction } from 'types'
import { fetchPoolTransactions } from 'data/pools/transactions'
import { PoolTickData } from 'data/pools/tickData'
import { useActiveNetworkVersion, useClients } from 'state/application/hooks'
import { PairInfoV3, useGetCLPair } from '../../hooks/usePairAPIV3'

export function useAllPoolData(): {
  [address: string]: {
    data: PoolData | undefined
    chronosData: PairInfoV3 | undefined
    lastUpdated: number | undefined
  }
} {
  const [network] = useActiveNetworkVersion()
  return useSelector((state: AppState) => state.pools.byAddress[network.id] ?? {})
}

export function useGaugePoolTranslation(): {
  [gaugeAddress: string]: string | undefined
} {
  return useSelector((state: AppState) => state.pools.gaugeToPoolAddress)
}

export function useUpdatePoolData(): (pools: [PoolData, string][]) => void {
  const dispatch = useDispatch<AppDispatch>()
  const [network] = useActiveNetworkVersion()
  return useCallback(
    (pools: [PoolData, string][]) => dispatch(updatePoolData({ pools, networkId: network.id })),
    [dispatch, network.id]
  )
}

export function useAddPoolKeys(): (pools: PairInfoV3[]) => void {
  const dispatch = useDispatch<AppDispatch>()
  const [network] = useActiveNetworkVersion()
  return useCallback(
    (poolsData: PairInfoV3[]) => dispatch(addPoolKeys({ poolsData, networkId: network.id })),
    [dispatch, network.id]
  )
}

export function useSinglePoolData(poolAddress: string): PoolData[] {
  const [network] = useActiveNetworkVersion()
  const poolData = useSelector((state: AppState) => state.pools.byAddress[network.id]?.[poolAddress]?.data)
  return poolData ? [poolData] : []

  // const allPoolData = useAllPoolData()
  // const addPoolKeys = useAddPoolKeys()
  // const untrackedAddresses: string[] = []
  // if (!Object.keys(allPoolData).includes(poolAddress)) {
  //   untrackedAddresses.push(poolAddress)
  // }

  // // get Dyson info for each untracker pair (useGetCLPair())
  // const untrackedPairs = useGetCLPair(poolAddress, untrackedAddresses.length > 0)

  // useEffect(() => {
  //   if (untrackedPairs) {
  //     addPoolKeys([untrackedPairs])
  //   }
  //   return
  // }, [addPoolKeys, untrackedPairs])

  // // filter for pools with data
  // const poolsWithData = [poolAddress]
  //   .map((address) => {
  //     const poolData = allPoolData[address]?.data
  //     return poolData ?? undefined
  //   })
  //   .filter(notEmpty)

  // return poolsWithData
}

export function usePoolDatas(poolAddresses: string[]): PoolData[] {
  // const dispatch = useDispatch<AppDispatch>()

  // dispatch(
  //   Contract.call({
  //     networkId: '42161',
  //     address: '0x000...',
  //     method: 'balanceOf',
  //     args: ['0x111...'],
  //   })
  // )
  // const blocks: (Block.BlockTransactionBase | null)[] = useSelector((state: AppState) => Block.selectMany(state))

  // console.log(blocks)

  return []
  // const allPoolData = useAllPoolData()
  // const addPoolKeys = useAddPoolKeys()
  // const untrackedAddresses = poolAddresses.reduce((accum: string[], address) => {
  //   if (!Object.keys(allPoolData).includes(address)) {
  //     accum.push(address)
  //   }
  //   return accum
  // }, [])

  // // get Dyson info for each untracker pair (useGetCLPair())

  // const untrackedPairs = useGetMultipleCLPairs(untrackedAddresses)

  // useEffect(() => {
  //   if (untrackedPairs && untrackedPairs.length > 0) {
  //     addPoolKeys(untrackedPairs)
  //   }
  //   return
  // }, [addPoolKeys, untrackedAddresses])

  // // filter for pools with data
  // const poolsWithData = poolAddresses
  //   .map((address) => {
  //     const poolData = allPoolData[address]?.data
  //     return poolData ?? undefined
  //   })
  //   .filter(notEmpty)

  // return poolsWithData
}

/**
 * Get top pools addresses that token is included in
 * If not loaded, fetch and store
 * @param address
 */
export function usePoolChartData(address: string): PoolChartEntry[] | undefined {
  const dispatch = useDispatch<AppDispatch>()
  const [activeNetwork] = useActiveNetworkVersion()

  const pool = useSelector((state: AppState) => state.pools.byAddress[activeNetwork.id]?.[address])
  const chartData = pool?.chartData
  const [error, setError] = useState(false)
  const { dataClient } = useClients()

  useEffect(() => {
    async function fetch() {
      const { error, data } = await fetchPoolChartData(address, dataClient)
      if (!error && data) {
        dispatch(updatePoolChartData({ poolAddress: address, chartData: data, networkId: activeNetwork.id }))
      }
      if (error) {
        setError(error)
      }
    }
    if (!chartData && !error) {
      fetch()
    }
  }, [address, dispatch, error, chartData, dataClient, activeNetwork.id])

  // return data
  return chartData
}

/**
 * Get all transactions on pool
 * @param address
 */
export function usePoolTransactions(address: string): Transaction[] | undefined {
  const dispatch = useDispatch<AppDispatch>()
  const [activeNetwork] = useActiveNetworkVersion()
  const pool = useSelector((state: AppState) => state.pools.byAddress[activeNetwork.id]?.[address])
  const transactions = pool?.transactions
  const [error, setError] = useState(false)
  const { dataClient } = useClients()

  useEffect(() => {
    async function fetch() {
      const { error, data } = await fetchPoolTransactions(address, dataClient)
      if (error) {
        setError(true)
      } else if (data) {
        dispatch(updatePoolTransactions({ poolAddress: address, transactions: data, networkId: activeNetwork.id }))
      }
    }
    if (!transactions && !error) {
      fetch()
    }
  }, [address, dispatch, error, transactions, dataClient, activeNetwork.id])

  // return data
  return transactions
}

export function usePoolTickData(
  address: string
): [PoolTickData | undefined, (poolAddress: string, tickData: PoolTickData) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const [activeNetwork] = useActiveNetworkVersion()
  const pool = useSelector((state: AppState) => state.pools.byAddress[activeNetwork.id]?.[address])
  const tickData = pool.tickData

  const setPoolTickData = useCallback(
    (address: string, tickData: PoolTickData) =>
      dispatch(updateTickData({ poolAddress: address, tickData, networkId: activeNetwork.id })),
    [activeNetwork.id, dispatch]
  )

  return [tickData, setPoolTickData]
}

export function useSelectedPool(): [string | undefined, (poolAddress: string | undefined) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const selectedPool = useSelector((state: AppState) => state.pools.selectedPoolAddress)

  const setSelectedPool = useCallback(
    (address: string | undefined) => dispatch(updateSelectedPool({ poolAddress: address })),
    [dispatch]
  )

  return [selectedPool, setSelectedPool]
}
