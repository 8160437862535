import React, { useState, useRef } from 'react'
import styled from 'styled-components'

interface CarouselProps {
  autoSlide?: boolean
  slideInterval?: number
}

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  justify-content: space-between;
  gap: 16px;

  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledCarouselElement = styled.div`
  height: 100%;
`

const Indicator = styled.div<{ isActive: boolean }>`
  width: ${({ isActive }) => (isActive ? '23px' : '5px')};
  height: 5px;
  border-radius: 100px;
  background-color: white;
  margin: 0 2px;
  transition: width 0.3s;
`

const IndicatorsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Carousel: React.FC<CarouselProps> = ({
  children,
  autoSlide = false,
  slideInterval = 5000,
}: React.PropsWithChildren<CarouselProps>) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const totalChildren = React.Children.count(children)

  const handleSlide = (index: number) => {
    if (containerRef.current) {
      const targetElement = containerRef.current.children[index] as HTMLElement
      const containerScrollLeft = containerRef.current.scrollLeft
      const targetOffsetLeft = targetElement.offsetLeft
      const scrollPosition = targetOffsetLeft - containerScrollLeft
      containerRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' })
    }
  }

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollPosition = containerRef.current.scrollLeft
      const newIndex = Math.round(scrollPosition / containerRef.current.clientWidth)
      setCurrentIndex(newIndex)
    }
  }

  React.useEffect(() => {
    if (autoSlide) {
      const intervalId = setInterval(() => {
        const nextIndex = (currentIndex + 1) % totalChildren
        handleSlide(nextIndex)
        setCurrentIndex(nextIndex)
      }, slideInterval)

      return () => clearInterval(intervalId)
    }
  }, [autoSlide, currentIndex, slideInterval, totalChildren])

  const modifiedChildren = React.Children.map(children, (child, index) => (
    <StyledCarouselElement key={index}>{child}</StyledCarouselElement>
  ))

  return (
    <>
      <CarouselContainer ref={containerRef} onScroll={handleScroll}>
        {modifiedChildren}
      </CarouselContainer>
      <IndicatorsContainer>
        {Array.from({ length: totalChildren }).map((_, index) => (
          <Indicator
            key={index}
            isActive={index === currentIndex}
            onClick={() => {
              handleSlide(index)
              setCurrentIndex(index)
            }}
          />
        ))}
      </IndicatorsContainer>
    </>
  )
}

export default Carousel
