export const FIRST_EPOCH_START = 1682553601
export const EPOCH_DURATION = 7 * 24 * 60 * 60
export const MIGRATION_EPOCH = 16

export function getEpochStartTimestamp(epoch: number) {
  const EPOCH_0_START = 1682553601
  return EPOCH_0_START + epoch * 604800
}

export function getEpochEndTimestamp(epoch: number) {
  return getEpochStartTimestamp(epoch + 1) - 1
}

export const getClosestEpoch = (timestamp: number) => {
  const epoch0 = getEpochStartTimestamp(0)
  const epoch = Math.floor((timestamp - epoch0) / 604800)

  return epoch
}

export function getCurrentEpoch() {
  return getClosestEpoch(Math.floor(Date.now() / 1000))
}
export function getPastEpochs() {
  const currentEpoch = getCurrentEpoch()
  const epochs = []
  for (let i = 1; i < currentEpoch - 1; i++) {
    epochs.push(i)
  }
  return epochs
}
