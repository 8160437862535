import {
  updateAssociatedGaugeBribingDetails,
  updateAssociatedGaugeRewardDistributionDetails,
  updateSelectedEpoch,
  updateSelectedGauge,
} from './actions'
import { createReducer } from '@reduxjs/toolkit'
import { SerializedToken } from 'state/user/actions'
import { AssociatedGaugeBribingDetails, AssociatedGaugeRewardDistributionDetails } from 'types/gauges'
import { getCurrentEpoch } from 'utils/epochManagement'

export interface GaugesState {
  // analytics data from
  selectedGaugeAddress: string | undefined
  selectedEpoch: number
  associatedGaugeRewardDistributionDetails: AssociatedGaugeRewardDistributionDetails[]
  associatedGaugeBribingDetails: AssociatedGaugeBribingDetails[] | undefined
}

export const initialState: GaugesState = {
  selectedGaugeAddress: undefined,
  selectedEpoch: getCurrentEpoch() - 1,
  associatedGaugeRewardDistributionDetails: [],
  associatedGaugeBribingDetails: undefined,
}

export default createReducer(initialState, (builder) =>
  builder
    // .addCase(updateGaugesData, (state, { payload: { pools, networkId } }) => {
    //   pools.map(
    //     (poolData) =>
    //       (state.byAddress[networkId][poolData.address] = {
    //         ...state.byAddress[networkId][poolData.address],
    //         data: poolData,
    //         lastUpdated: currentTimestamp(),
    //       })
    //   )
    // })
    // // add address to byAddress keys if not included yet
    // .addCase(addGaugesKeys, (state, { payload: { poolAddresses, networkId } }) => {
    //   poolAddresses.map((address) => {
    //     if (!state.byAddress[networkId][address]) {
    //       state.byAddress[networkId][address] = {
    //         data: undefined,
    //         chartData: undefined,
    //         transactions: undefined,
    //         lastUpdated: undefined,
    //         tickData: undefined,
    //       }
    //     }
    //   })
    // })
    // .addCase(updateGaugesChartData, (state, { payload: { poolAddress, chartData, networkId } }) => {
    //   state.byAddress[networkId][poolAddress] = { ...state.byAddress[networkId][poolAddress], chartData: chartData }
    // })
    // .addCase(updateGaugesTransactions, (state, { payload: { poolAddress, transactions, networkId } }) => {
    //   state.byAddress[networkId][poolAddress] = { ...state.byAddress[networkId][poolAddress], transactions }
    // })
    // .addCase(updateTickData, (state, { payload: { poolAddress, tickData, networkId } }) => {
    //   state.byAddress[networkId][poolAddress] = { ...state.byAddress[networkId][poolAddress], tickData }
    // })
    .addCase(updateSelectedGauge, (state, { payload: { poolAddress } }) => {
      state.selectedGaugeAddress = poolAddress
    })
    .addCase(updateSelectedEpoch, (state, { payload: { selectedEpoch } }) => {
      state.selectedEpoch = selectedEpoch
    })
    .addCase(
      updateAssociatedGaugeRewardDistributionDetails,
      (state, { payload: { associatedGaugeRewardDistributionDetails } }) => {
        state.associatedGaugeRewardDistributionDetails = associatedGaugeRewardDistributionDetails
      }
    )
    .addCase(updateAssociatedGaugeBribingDetails, (state, { payload: { associatedGaugeBribingDetails } }) => {
      state.associatedGaugeBribingDetails = associatedGaugeBribingDetails
    })
)
