import { createAction } from '@reduxjs/toolkit'
import { AssociatedGaugeVotingDetails } from 'types/votes'

export const updateTotalVeCHR = createAction<{ totalVeCHR: number | undefined }>('voter/setTotalVeCHR')

export const updateSelectedEpoch = createAction<{
  selectedEpoch: number
}>('voter/setSelectedEpoch')

export const updateSelectedEpochGaugesVotingDetails = createAction<{
  details: AssociatedGaugeVotingDetails[]
}>('voter/setSelectedEpochGaugesVotingDetails')

export const updateVeCHRForSelectedEpoch = createAction<{
  totalVeCHRForSelectedEpoch: number | undefined
}>('voter/setVeCHRForSelectedEpoch')
