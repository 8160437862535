import { AssociatedGaugeVotingDetails } from 'types/votes'
import {
  updateSelectedEpoch,
  updateSelectedEpochGaugesVotingDetails,
  updateTotalVeCHR,
  updateVeCHRForSelectedEpoch,
} from './actions'
import { createReducer } from '@reduxjs/toolkit'
import { getCurrentEpoch } from 'utils/epochManagement'

export interface VoterData {
  totalVeCHR: number | undefined
  totalVeCHRForSelectedEpoch: number | undefined
  selectedEpoch: number
  selectedEpochGaugesVotingDetails: AssociatedGaugeVotingDetails[]
}

const initialState: VoterData = {
  totalVeCHR: undefined,
  totalVeCHRForSelectedEpoch: undefined,
  selectedEpoch: getCurrentEpoch() - 1,
  selectedEpochGaugesVotingDetails: [],
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateTotalVeCHR, (state, { payload: { totalVeCHR } }) => {
      state.totalVeCHR = totalVeCHR
    })

    .addCase(updateSelectedEpoch, (state, { payload: { selectedEpoch } }) => {
      state.selectedEpoch = selectedEpoch
    })

    .addCase(updateSelectedEpochGaugesVotingDetails, (state, { payload: { details } }) => {
      state.selectedEpochGaugesVotingDetails = details
    })

    .addCase(updateVeCHRForSelectedEpoch, (state, { payload: { totalVeCHRForSelectedEpoch } }) => {
      console.log('Calling in reducer ', totalVeCHRForSelectedEpoch)
      state.totalVeCHRForSelectedEpoch = totalVeCHRForSelectedEpoch
    })
})
