import { currentTimestamp } from './../../utils/index'
import {
  updatePoolData,
  addPoolKeys,
  updatePoolChartData,
  updatePoolTransactions,
  updateTickData,
  updateSelectedPool,
} from './actions'
import { createReducer } from '@reduxjs/toolkit'
import { SerializedToken } from 'state/user/actions'
import { Transaction } from 'types'
import { PoolTickData } from 'data/pools/tickData'
import { SupportedNetwork } from 'constants/networks'
import { PairInfoV3 } from '../../hooks/usePairAPIV3'

export interface Pool {
  address: string
  token0: SerializedToken
  token1: SerializedToken
}

export interface PoolData {
  // basic token info
  address: string
  feeTier: number

  token0: {
    name: string
    symbol: string
    address: string
    decimals: number
    derivedETH: number
  }

  token1: {
    name: string
    symbol: string
    address: string
    decimals: number
    derivedETH: number
  }

  // for tick math
  liquidity: number
  sqrtPrice: number
  tick: number

  // volume
  volumeUSD: number
  volumeUSDChange: number
  volumeUSDWeek: number

  // liquidity
  tvlUSD: number
  tvlUSDChange: number

  // prices
  token0Price: number
  token1Price: number

  // token amounts
  tvlToken0: number
  tvlToken1: number
}

export type PoolChartEntry = {
  date: number
  volumeUSD: number
  totalValueLockedUSD: number
  feesUSD: number
}

export interface PoolsState {
  // analytics data from
  selectedPoolAddress: string | undefined
  byAddress: {
    [networkId: string]: {
      [address: string]: {
        data: PoolData | undefined
        chronosData: PairInfoV3 | undefined
        chartData: PoolChartEntry[] | undefined
        transactions: Transaction[] | undefined
        lastUpdated: number | undefined
        tickData: PoolTickData | undefined
      }
    }
  }

  gaugeToPoolAddress: {
    [gaugeAddress: string]: string
  }
}

export const initialState: PoolsState = {
  selectedPoolAddress: undefined,
  byAddress: {
    [SupportedNetwork.ETHEREUM]: {},
    [SupportedNetwork.ARBITRUM]: {},
    [SupportedNetwork.OPTIMISM]: {},
    [SupportedNetwork.POLYGON]: {},
    [SupportedNetwork.CELO]: {},
    [SupportedNetwork.BNB]: {},
    [SupportedNetwork.AVALANCHE]: {},
  },
  gaugeToPoolAddress: {},
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updatePoolData, (state, { payload: { pools, networkId } }) => {
      pools.map(
        (poolData) =>
          (state.byAddress[networkId][poolData[1]] = {
            ...state.byAddress[networkId][poolData[1]],
            data: poolData[0],
            lastUpdated: currentTimestamp(),
          })
      )
    })
    // add address to byAddress keys if not included yet
    .addCase(addPoolKeys, (state, { payload: { poolsData, networkId } }) => {
      poolsData.map((pool) => {
        if (!state.byAddress[networkId][pool.pair_address.toLowerCase()]) {
          state.byAddress[networkId][pool.pair_address.toLowerCase()] = {
            data: undefined,
            chronosData: pool,
            chartData: undefined,
            transactions: undefined,
            lastUpdated: undefined,
            tickData: undefined,
          }
        } else {
          state.byAddress[networkId][pool.pair_address.toLowerCase()] = {
            ...state.byAddress[networkId][pool.pair_address.toLowerCase()],
            chronosData: pool,
          }
        }

        if (pool.gauge) {
          state.gaugeToPoolAddress[pool.gauge.toLowerCase()] = pool.pair_address.toLowerCase()
        }
      })
    })
    .addCase(updatePoolChartData, (state, { payload: { poolAddress, chartData, networkId } }) => {
      state.byAddress[networkId][poolAddress] = { ...state.byAddress[networkId][poolAddress], chartData: chartData }
    })
    .addCase(updatePoolTransactions, (state, { payload: { poolAddress, transactions, networkId } }) => {
      state.byAddress[networkId][poolAddress] = { ...state.byAddress[networkId][poolAddress], transactions }
    })
    .addCase(updateTickData, (state, { payload: { poolAddress, tickData, networkId } }) => {
      state.byAddress[networkId][poolAddress] = { ...state.byAddress[networkId][poolAddress], tickData }
    })
    .addCase(updateSelectedPool, (state, { payload: { poolAddress } }) => {
      state.selectedPoolAddress = poolAddress
    })
)
