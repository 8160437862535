import { useEffect, useMemo, useState } from 'react'
import { BigNumber } from 'ethers'
// import { NEVER_RELOAD, useSingleCallResult } from '../state/multicall/hooks'
import { useVoterV2Contract, useVoterV3Contract } from './useContract'
import { useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { MIGRATION_EPOCH, getCurrentEpoch, getEpochEndTimestamp } from 'utils/epochManagement'
import { AssociatedGaugeVotingDetails } from 'types/votes'
import { useGaugeAddressesWithRewardsOfEpoch } from 'data/gauges/rewards'
import { NULL_ADDRESS } from 'constants/Constants'

/**
 * Get total voting power
 */
export default function useTotalVeCHR(): BigNumber | undefined {
  return undefined
  // const VoterContract = useVoterV3Contract()
  // const totalVotingPower = useSingleCallResult(VoterContract, 'totalWeight', [], NEVER_RELOAD)

  // return useMemo(() => {
  //   if (totalVotingPower.loading) return null
  //   if (totalVotingPower.result) {
  //     return totalVotingPower.result[0]
  //   }
  //   return undefined
  // }, [totalVotingPower.loading, totalVotingPower.result])
}

export function useGetTotalVeCHR(): BigNumber | undefined {
  const VoterContract = useVoterV3Contract()
  // const blockNumber = useBlockNumber()
  const result = useSingleCallResult(VoterContract ? VoterContract : null, 'totalWeight', [], {
    gasRequired: 8000000,
    blocksPerFetch: Infinity,
  })

  const [VeCHR, setVeCHR] = useState<BigNumber | undefined>(undefined)

  useEffect(() => {
    if (!VoterContract) return
    if (!result) return
    if (result.loading) return
    if (result.result && result.result[0]) {
      setVeCHR(result.result[0])
    }
    return
  }, [VoterContract, result.loading])
  return VeCHR
}

export function useTotalVeCHRForEpoch(epoch: number): number | undefined {
  const VoterV3Contract = useVoterV3Contract()
  const epochEndTimestamp = getEpochEndTimestamp(epoch)
  const result = useSingleCallResult(VoterV3Contract, 'totalWeightAt', [epochEndTimestamp], {
    gasRequired: 8000000,
    blocksPerFetch: Infinity,
  })

  const [totalWeight, setTotalWeight] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!VoterV3Contract) return
    if (!result) return
    if (result.loading) return
    if (result.result && result.result[0]) {
      setTotalWeight(Number(result.result[0]))
    }
    return
  }, [VoterV3Contract, result.loading])

  return totalWeight
}

export function useAssociatedGaugeVotingDetailsForEpoch(epoch: number): AssociatedGaugeVotingDetails[] {
  const VoterV2Contract = useVoterV2Contract()
  const VoterV3Contract = useVoterV3Contract()

  const {
    loading: loadingGaugesWithRewards,
    error: errorGaugesWithRewards,
    addresses,
  } = useGaugeAddressesWithRewardsOfEpoch(epoch)

  const result = useSingleContractMultipleData(
    VoterV3Contract,
    'weightsPerEpoch',
    addresses ? addresses.map((address) => [epoch, address]) : []
  )

  const associatedGaugeVotingDetails = useMemo<AssociatedGaugeVotingDetails[]>(() => {
    if (result && addresses) {
      return result
        .map((r, i) => {
          if (r.valid && !r.loading && r.result) {
            return {
              gaugeAddress: addresses[i],
              voteWeight: r.result ? Number(r.result[i]) : 0,
            }
          } else {
            return null
          }
        })
        .filter((e) => e != null)
    } else {
      return []
    }
  }, [result, addresses])

  // if (currentEpoch < MIGRATION_EPOCH) {
  //   const result = useSingleContractMultipleData(VoterV3Contract, 'getVotingDetails', [])
  // }

  return associatedGaugeVotingDetails
}
