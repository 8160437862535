import { AppState, AppDispatch } from './../index'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAssociatedGaugeBribingDetails,
  updateAssociatedGaugeRewardDistributionDetails,
  updateSelectedEpoch,
  updateSelectedGauge,
} from './actions'
import { AssociatedGaugeBribingDetails, AssociatedGaugeRewardDistributionDetails } from 'types/gauges'

// export function useAllGaugesData(): {
//   [address: string]: { data: PoolData | undefined; lastUpdated: number | undefined }
// } {
//   const [network] = useActiveNetworkVersion()
//   return useSelector((state: AppState) => state.gauges.byAddress[network.id] ?? {})
// }

// export function useUpdatePoolData(): (pools: PoolData[]) => void {
//   const dispatch = useDispatch<AppDispatch>()
//   const [network] = useActiveNetworkVersion()
//   return useCallback((pools: PoolData[]) => dispatch(updateGaugesData({ pools, networkId: network.id })), [
//     dispatch,
//     network.id,
//   ])
// }

// export function useAddGaugesKeys(): (addresses: string[]) => void {
//   const dispatch = useDispatch<AppDispatch>()
//   const [network] = useActiveNetworkVersion()
//   return useCallback((poolAddresses: string[]) => dispatch(addGaugesKeys({ poolAddresses, networkId: network.id })), [
//     dispatch,
//     network.id,
//   ])
// }

// export function useGaugesDatas(gaugesAddresses: string[]): PoolData[] {
//   const allGaugesData = useAllGaugesData()
//   const addGaugesKeys = useAddGaugesKeys()

//   const untrackedAddresses = gaugesAddresses.reduce((accum: string[], address) => {
//     if (!Object.keys(allGaugesData).includes(address)) {
//       accum.push(address)
//     }
//     return accum
//   }, [])

//   useEffect(() => {
//     if (untrackedAddresses) {
//       addGaugesKeys(untrackedAddresses)
//     }
//     return
//   }, [addGaugesKeys, untrackedAddresses])

//   // filter for pools with data
//   const gaugesWithData = gaugesAddresses
//     .map((address) => {
//       const gaugeData = allGaugesData[address]?.data
//       return gaugeData ?? undefined
//     })
//     .filter(notEmpty)

//   return gaugesWithData
// }

export function useSelectedGauge(): [string | undefined, (poolAddress: string | undefined) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const selectedGauge = useSelector((state: AppState) => state.gauges.selectedGaugeAddress)

  const setSelectedGauge = useCallback(
    (address: string | undefined) => dispatch(updateSelectedGauge({ poolAddress: address })),
    [dispatch]
  )

  return [selectedGauge, setSelectedGauge]
}

export function useSelectedEpoch(): [number, (selectedEpoch: number) => void] {
  const selectedEpoch: number = useSelector((state: AppState) => state.gauges.selectedEpoch)

  const dispatch = useDispatch<AppDispatch>()
  const _updateSelectedEpoch: (selectedEpoch: number) => void = useCallback(
    (selectedEpoch: number) => dispatch(updateSelectedEpoch({ selectedEpoch })),
    [dispatch]
  )

  return [selectedEpoch, _updateSelectedEpoch]
}

export function useAssociatedGaugeRewardDistributionDetails(): [
  AssociatedGaugeRewardDistributionDetails[],
  (associatedGaugeRewardDistributionDetails: AssociatedGaugeRewardDistributionDetails[]) => void
] {
  const details: AssociatedGaugeRewardDistributionDetails[] = useSelector(
    (state: AppState) => state.gauges.associatedGaugeRewardDistributionDetails
  )
  const dispatch = useDispatch<AppDispatch>()
  const _updateAssociatedGaugeRewardDistributionDetails: (
    associatedGaugeRewardDistributionDetails: AssociatedGaugeRewardDistributionDetails[]
  ) => void = useCallback(
    (associatedGaugeRewardDistributionDetails: AssociatedGaugeRewardDistributionDetails[]) =>
      dispatch(updateAssociatedGaugeRewardDistributionDetails({ associatedGaugeRewardDistributionDetails })),
    [dispatch]
  )

  return [details, _updateAssociatedGaugeRewardDistributionDetails]
}

export function useAssociatedGaugeBribingDetails(): [
  AssociatedGaugeBribingDetails[] | undefined,
  (associatedGaugeBribingDetails: AssociatedGaugeBribingDetails[] | undefined) => void
] {
  const details: AssociatedGaugeBribingDetails[] | undefined = useSelector(
    (state: AppState) => state.gauges.associatedGaugeBribingDetails
  )
  const dispatch = useDispatch<AppDispatch>()
  const _updateAssociatedGaugeBribingDetails: (
    associatedGaugeBribingDetails: AssociatedGaugeBribingDetails[] | undefined
  ) => void = useCallback(
    (associatedGaugeBribingDetails: AssociatedGaugeBribingDetails[] | undefined) =>
      dispatch(updateAssociatedGaugeBribingDetails({ associatedGaugeBribingDetails })),
    [dispatch]
  )

  return [details, _updateAssociatedGaugeBribingDetails]
}
