// import { useUpdatePoolData, useAllGaugesData, useAddGaugesKeys } from './hooks'
import { useEffect, useMemo, useState } from 'react'
import { useTopPoolAddresses } from 'data/pools/topPools'
import { usePoolDatas } from 'data/pools/poolData'
import { POOL_HIDE } from '../../constants'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { useTopGaugesAddresses } from 'data/gauges/topGauges'
import { notEmpty } from 'utils'
import { useAllPoolData } from 'state/pools/hooks'
import { useGetAllClPairRewards, useGetAllPairRewards } from 'hooks/useRewardAPIV3'
import {
  useAssociatedGaugeBribingDetails,
  useAssociatedGaugeRewardDistributionDetails,
  useSelectedEpoch,
} from './hooks'
import { useGaugeBribingDetailsForCurrentEpoch, useGaugeRewardDistributionPerEpoch } from 'data/gauges/rewards'
import { useAssociatedGaugeVotingDetailsForEpoch } from 'hooks/useVoter'

export default function Updater(): null {
  // updaters
  // const [currentNetwork] = useActiveNetworkVersion()
  // const updatePoolData = useUpdatePoolData()
  // const addGaugesKeys = useAddGaugesKeys()

  // const rewardsCl = useGetAllClPairRewards()
  // const rewards = useGetAllPairRewards()

  const [associatedRewardDistribution, setAssociatedRewardDistribution] = useAssociatedGaugeRewardDistributionDetails()
  const [associatedBribingDetails, setAssociatedBribingDetails] = useAssociatedGaugeBribingDetails()

  const [selectedEpoch, setSelectedEpoch] = useSelectedEpoch()
  const rewardDistributon = useGaugeRewardDistributionPerEpoch(selectedEpoch)
  const fetchedBribingDetails = useGaugeBribingDetailsForCurrentEpoch()
  const [lastUpdatedEpoch, setLastUpdatedEpoch] = useState(-1)

  useEffect(() => {
    if (rewardDistributon.distribution && selectedEpoch != lastUpdatedEpoch) {
      console.log('Updating distribution ', rewardDistributon.distribution)
      setAssociatedRewardDistribution(
        rewardDistributon.distribution.map((reward) => {
          return {
            gaugeAddress: reward.id.toLowerCase(),
            amount: reward.amount,
            poolAddress: reward.poolAddress,
          }
        })
      )
      setLastUpdatedEpoch(selectedEpoch)
    }
  }, [rewardDistributon, selectedEpoch])

  useEffect(() => {
    if (fetchedBribingDetails) {
      setAssociatedBribingDetails(Object.values(fetchedBribingDetails))
    }
  }, [fetchedBribingDetails])

  // // data
  // // const allPoolData = useAllPoolData()
  // const allPoolData = useAllPoolData()
  // const poolDatas = useMemo(() => {
  //   return Object.values(allPoolData)
  //     .map((p) => p.data)
  //     .filter(notEmpty)
  // }, [allPoolData])
  // const poolAddresses = useMemo(() => {
  //   return Object.keys(allPoolData)
  // }, [allPoolData])

  // // const { loading, error, pooladdresses } = useTopPoolAddresses()
  // const { loading, error, addresses } = useTopGaugesAddresses(poolAddresses)

  // // add top pools on first load
  // useEffect(() => {
  //   if (addresses && !error && !loading) {
  //     addGaugesKeys(addresses)
  //   }
  // }, [addGaugesKeys, addresses, error, loading])

  // load data for pools we need to hide
  // useEffect(() => {
  //   addPoolKeys(POOL_HIDE[currentNetwork.id])
  // }, [addPoolKeys, currentNetwork.id])

  // detect for which addresses we havent loaded pool data yet
  // const unfetchedPoolAddresses = useMemo(() => {
  //   return Object.keys(allPoolData).reduce((accum: string[], key) => {
  //     const poolData = allPoolData[key]
  //     if (!poolData.data || !poolData.lastUpdated) {
  //       accum.push(key)
  //     }
  //     return accum
  //   }, [])
  // }, [allPoolData])

  // update unloaded pool entries with fetched data
  // const { error: poolDataError, loading: poolDataLoading, data: poolDatas } = usePoolDatas(unfetchedPoolAddresses)

  // useEffect(() => {
  //   if (poolDatas && !poolDataError && !poolDataLoading) {
  //     updatePoolData(Object.values(poolDatas))
  //   }
  // }, [poolDataError, poolDataLoading, poolDatas, updatePoolData])

  return null
}
