import { useCallback, useEffect } from 'react'
import { useAllLists } from './hooks'
import useInterval from '../../hooks/useInterval'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { acceptTokensUpdate } from './actions'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useFetchChronosCallback } from 'hooks/useFetchChronosCallback'

export default function Updater(): null {
  const dispatch = useAppDispatch()
  const isWindowVisible = useIsWindowVisible()

  // get all loaded lists, and the active urls
  const lists = useAllLists()

  const fetchList = useFetchChronosCallback()
  const fetchAllListsCallback = useCallback(() => {
    if (!isWindowVisible) return
    fetchList().catch((error) => console.debug('interval list fetching error', error))
  }, [fetchList, isWindowVisible, lists])

  // fetch all lists every 10 minutes, but only after we initialize library
  useInterval(fetchAllListsCallback, 1000 * 60 * 10)

  // whenever a list is not loaded and not loading, try again to load it
  useEffect(() => {
    if (!lists.current && !lists.loadingRequestId && !lists.error) {
      fetchList().catch((error) => console.debug('list added fetching error', error))
    }
  }, [dispatch, fetchList, lists])

  // automatically update lists if versions are minor/patch
  useEffect(() => {
    if (lists.current && lists.pendingUpdate) {
      const bump = lists.pendingUpdate?.tokens && lists.current?.tokens !== lists.pendingUpdate?.tokens
      if (bump) {
        dispatch(acceptTokensUpdate())
      }
    }
  }, [dispatch, lists])

  return null
}
