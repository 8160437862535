import React, { Dispatch, SetStateAction, ReactNode } from 'react'
import { BarChart, ResponsiveContainer, XAxis, Tooltip, Bar, YAxis, CartesianGrid } from 'recharts'
import styled from 'styled-components'
import { RowBetween } from 'components/Row'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useTheme from 'hooks/useTheme'
import { VolumeWindow } from 'types'
import { LoadingRows } from 'components/Loader'
dayjs.extend(utc)

const DEFAULT_HEIGHT = 500

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;

  > * {
    font-size: 1rem;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: column;
  align-items: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  > * {
      font-size: 10px;
    }
  `};
`

export type LineChartProps = {
  data: any[]
  color?: string | undefined
  height?: number | undefined
  minHeight?: number
  setValue?: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setLabel?: Dispatch<SetStateAction<string | undefined>> // used for label of valye
  value?: number
  label?: string
  activeWindow?: VolumeWindow
  topLeft?: ReactNode | undefined
  topRight?: ReactNode | undefined
  bottomLeft?: ReactNode | undefined
  bottomRight?: ReactNode | undefined
} & React.HTMLAttributes<HTMLDivElement>

const CustomBar = (props: any) => {
  const { x, y, width, height, barColor } = props
  const borderRadius = 5

  const pathData = `
    M ${x},${y + borderRadius}
    A ${borderRadius},${borderRadius} 0 0 1 ${x + borderRadius},${y}
    L ${x + width - borderRadius},${y}
    A ${borderRadius},${borderRadius} 0 0 1 ${x + width},${y + borderRadius}
    L ${x + width},${y + height}
    L ${x},${y + height}
    Z
  `

  return <path d={pathData} fill={barColor} />
}

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props

  const parts = payload.value.split(' ')

  const symbol = parts[0]
  const weightPercentage = parts[1]

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={3} textAnchor="end" fontSize={8} transform="rotate(-90)" fill="#ffffff">
        {symbol}
        <tspan fontSize={8} fill="rgba(255,255,255,0.5)">
          {' '}
          {`(${weightPercentage}%)`}
        </tspan>
      </text>
    </g>
  )
}

const Chart = ({
  data,
  color = '#56B2A4',
  setValue,
  setLabel,
  value,
  label,
  activeWindow,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  minHeight = DEFAULT_HEIGHT,
  ...rest
}: LineChartProps) => {
  const theme = useTheme()
  const sortedData = data.sort((a, b) => b.voteWeight - a.voteWeight)

  return (
    <Wrapper minHeight={minHeight} {...rest}>
      {data?.length === 0 ? (
        <LoadingRows>
          <div />
          <div />
          <div />
        </LoadingRows>
      ) : (
        <ResponsiveContainer width="95%" height={450}>
          <BarChart
            data={sortedData}
            margin={{
              top: 30,
              right: 30,
              left: 20,
              bottom: 20,
            }}
            onMouseLeave={() => {
              setLabel && setLabel(undefined)
              setValue && setValue(undefined)
            }}
          >
            <CartesianGrid strokeDasharray="0" vertical={false} stroke="rgba(0,0,0, 0.14)" />
            <XAxis
              dataKey="symbol"
              axisLine
              tickLine
              fontSize={10}
              tick={CustomizedAxisTick}
              height={110}
              interval={0}
            />
            <YAxis
              fontSize={14}
              label={{ value: 'Vote Weight', angle: -90, position: 'insideLeft', fill: '#ffffff' }}
            />
            <Bar dataKey="voteWeight" shape={CustomBar} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Wrapper>
  )
}

export default Chart
