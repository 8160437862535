import { nanoid } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { fetchTokenList } from '../state/global/actions'
import getChronosTokenList, { TokenList } from '../utils/getChronosTokenList'
import { useAppDispatch } from './useAppDispatch'

export function useFetchChronosCallback(): (sendDispatch?: boolean) => Promise<TokenList> {
  const dispatch = useAppDispatch()

  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (sendDispatch = true) => {
      const requestId = nanoid()
      sendDispatch && dispatch(fetchTokenList.pending({ requestId }))
      return getChronosTokenList()
        .then((tokenList) => {
          sendDispatch && dispatch(fetchTokenList.fulfilled({ tokenList, requestId }))
          return tokenList
        })
        .catch((error) => {
          console.debug(`Failed to get list`, error)
          sendDispatch && dispatch(fetchTokenList.rejected({ requestId, errorMessage: error.message }))
          throw error
        })
    },
    [dispatch]
  )
}
