import { Connector } from '@web3-react/types'
import { networkConnection } from 'connection'
import { getConnection } from 'connection'
import { useEffect } from 'react'
// import { useAppDispatch, useAppSelector } from 'state'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'state'

async function connect(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly()
    } else {
      await connector.activate()
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`)
  }
}

export default function useEagerlyConnect() {
  const dispatch = useDispatch<AppDispatch>()

  // const rehydrated = useAppSelector((state) => state._persist.rehydrated)

  useEffect(() => {
    try {
      connect(networkConnection.connector)
    } catch {
      return
    }
  }, [dispatch])
}
