import { useEffect, useMemo, useState } from 'react'
import { BigNumber } from 'ethers'
// import { NEVER_RELOAD, useSingleCallResult, useSingleContractMultipleData } from '../state/multicall/hooks'
import { usePairAPIV3Contract } from './useContract'
import { NULL_ADDRESS } from 'constants/Constants'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { PairApiV3 } from 'types/v3'
import { useContractRead } from 'wagmi'
import { PAIR_API_V3_ABI, PAIR_API_V3_ADDRESS } from '../constants/abis/PairApiV3'

export interface PairInfoV3 {
  pair_address: string // Address
  symbol: string
  name: string
  decimals: number
  stable: boolean
  total_supply: string // BigNumber
  /**
   * Uniswap V3 Pool Address
   */
  clPool: string // Address
  /**
   * Dyson Pool Address
   * Equal to the pair_address
   */
  dysonPool: string // Address
  /**
   * Dyson Strategy Address
   */
  dysonStrategy: string // Address
  feeAmount: number

  // Token pair info
  /**
   * Token 1 address
   */
  token0: string
  token0_symbol: string
  token0_decimals: number
  reserve0: string // BigNumber
  // claimable0: BigNumber

  /**
   * Token 1 address
   */
  token1: string // Address
  token1_symbol: string
  token1_decimals: number
  reserve1: string // BigNumber
  // claimable1: BigNumber

  // pairs gauge
  gauge: string
  gauge_total_supply: string // BigNumber
  gauge_total_weight: string // BigNumber
  /**
   * address
   */
  fee: string
  /**
   * Aaddress
   */
  bribe: string
  emissions: string // BigNumber
  /**
   * Aaddress
   */
  emissions_token: string
  emissions_token_decimals: number

  // User deposit
  // account_lp_balance: BigNumber
  // account_token0_balance: BigNumber
  // account_token1_balance: BigNumber
  // account_gauge_balance: BigNumber
  // account_gauge_total_weight: BigNumber
  // account_gauge_earned: BigNumber

  inactive_gauge: boolean
}

const parseResult = (result: any): PairInfoV3 => {
  return {
    pair_address: result.pair_address.toLowerCase(),
    symbol: result.symbol,
    name: result.name,
    decimals: result.decimals.toNumber(),
    stable: result.stable,
    clPool: result.clPool.toLowerCase(),
    dysonPool: result.dysonPool.toLowerCase(),
    dysonStrategy: result.dysonStrategy,
    feeAmount: result.feeAmount.toNumber(),
    token0: result.token0.toLowerCase(),
    token0_symbol: result.token0_symbol,
    token0_decimals: result.token0_decimals.toNumber(),
    token1: result.token1.toLowerCase(),
    token1_symbol: result.token1_symbol,
    token1_decimals: result.token1_decimals.toNumber(),
    gauge: result.gauge.toLowerCase(),
    fee: result.fee.toLowerCase(),
    bribe: result.bribe.toLowerCase(),
    emissions_token: result.emissions_token.toLowerCase(),
    emissions_token_decimals: result.emissions_token_decimals.toNumber(),
    inactive_gauge: result.inactive_gauge,
    gauge_total_supply: (result.gauge_total_supply as BigNumber)._hex,
    gauge_total_weight: (result.gauge_total_weight as BigNumber)._hex,
    emissions: (result.emissions as BigNumber)._hex,
    reserve0: (result.reserve0 as BigNumber)._hex,
    reserve1: (result.reserve1 as BigNumber)._hex,
    total_supply: (result.total_supply as BigNumber)._hex,
  }
}

export function useGetAllChronosPairs(): PairInfoV3[] | null {
  const PairApiV3Contract = usePairAPIV3Contract()
  // const blockNumber = useBlockNumber()
  const result = useSingleCallResult(
    PairApiV3Contract ? PairApiV3Contract : null,
    'getAllPair',
    [NULL_ADDRESS, 300, 0],
    { gasRequired: 50000000000 }
  )

  const [Pairs, setPairs] = useState<PairInfoV3[] | null>(null)

  useEffect(() => {
    if (!PairApiV3Contract) return
    if (!result) return
    if (result.loading) return
    if (result.result) {
      const unfilteredPairs: PairInfoV3[] = result.result.Pairs.map((result: any): PairInfoV3 => parseResult(result))
      const filteredPairs = unfilteredPairs.filter((pair) => pair && pair.pair_address !== NULL_ADDRESS)
      console.log('Fetched the pairs ', filteredPairs)
      setPairs(filteredPairs)
    }
  }, [PairApiV3Contract, result.loading])

  return Pairs
}
/**
 * Get total voting power
 */
export function useGetAllChronosCLPairs(): PairInfoV3[] | null {
  const PairApiV3Contract = usePairAPIV3Contract()
  // const blockNumber = useBlockNumber()
  console.log('Fetching v3 pairs')
  const result = useSingleCallResult(
    PairApiV3Contract ? PairApiV3Contract : null,
    'getAllPair',
    [NULL_ADDRESS, 80, 0],
    { gasRequired: 800000000000 }
  )

  const [Pairs, setPairs] = useState<PairInfoV3[] | null>(null)

  useEffect(() => {
    if (!PairApiV3Contract) return
    if (!result) return
    if (result.loading) return
    if (result.result) {
      const unfilteredPairs: PairInfoV3[] = result.result.Pairs.map((result: any): PairInfoV3 => parseResult(result))
      const filteredPairs = unfilteredPairs.filter((pair) => pair && pair.pair_address !== NULL_ADDRESS)
      setPairs(filteredPairs)
    }
  }, [PairApiV3Contract, result.loading])

  return Pairs
}

/**
 * Get total voting power
 */
export function useGetCLPair(address: string | undefined): PairInfoV3 | null {
  const PairApiV3Contract = usePairAPIV3Contract()
  const result = useSingleCallResult(address ? PairApiV3Contract : null, 'getCLPair', [address, NULL_ADDRESS], {
    gasRequired: 8000000,
    blocksPerFetch: Infinity,
  })
  return useMemo(() => {
    if (!address) return null
    if (!PairApiV3Contract) return null
    if (!result) return null
    if (result.loading) return null
    if (result.result) {
      return parseResult(result.result._pairInfo)
    }
    return null
  }, [PairApiV3Contract, address, result.loading])
}
