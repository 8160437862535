import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../index'
import { Token, TokenList } from 'utils/getChronosTokenList'

export function useAllLists(): {
  readonly current: TokenList | null
  readonly pendingUpdate: TokenList | null
  readonly loadingRequestId: string | null
  readonly error: string | null
} {
  return useSelector<AppState, AppState['global']['data']>((state) => state.global.data)
}

// function to get all tokens from chronos api
export function useGetChronosTokens(): { [address: string]: Token } {
  const lists = useAllLists()
  return useMemo(() => {
    if (!lists) return {}
    const current = lists?.current?.tokens
    if (!current) return {}
    return current
  }, [lists])
}
