import { useEffect, useState } from 'react'
import { useSubgraphStatus } from './hooks'
import { useFetchedSubgraphStatus } from '../../data/application'
import { NULL_ADDRESS } from 'constants/Constants'
import { useSelectedPool } from 'state/pools/hooks'
import { useSelectedGauge } from 'state/gauges/hooks'

export default function Updater(): null {
  // subgraph status
  const [status, updateStatus] = useSubgraphStatus()
  const { available, syncedBlock: newSyncedBlock, headBlock, chainId } = useFetchedSubgraphStatus()

  const syncedBlock = status.syncedBlock

  useEffect(() => {
    if (status.available === null && available !== null) {
      updateStatus(available, syncedBlock, headBlock, chainId)
    }
    if (!status.syncedBlock || (status.syncedBlock !== newSyncedBlock && syncedBlock)) {
      updateStatus(status.available, newSyncedBlock, headBlock, chainId)
    }
  }, [available, headBlock, newSyncedBlock, status.available, status.syncedBlock, syncedBlock, updateStatus])

  const [selectedPool, setSelectedPool] = useSelectedPool()
  const [selectedGauge, setSelectedGauge] = useSelectedGauge()
  const [oldSelectedPool, setOldSelectedPool] = useState<string | undefined>(undefined)
  const [oldSelectedGauge, setOldSelectedGauge] = useState<string | undefined>(undefined)

  //// SELECTED PAGE
  useEffect(() => {
    if (selectedPool !== oldSelectedPool) {
      setOldSelectedPool(selectedPool)
      setOldSelectedGauge(undefined)
      setSelectedGauge(undefined)
    } else if (selectedGauge !== oldSelectedGauge) {
      setOldSelectedGauge(selectedGauge)
      setOldSelectedPool(undefined)
      setSelectedPool(undefined)
    }
  }, [selectedGauge, selectedPool, setSelectedGauge, setSelectedPool])

  return null
}
