import React, { useEffect, useState } from 'react'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import { RowFixed, TableRowBetween } from 'components/Row'
import useTheme from 'hooks/useTheme'
import { useProtocolTransactions } from 'state/protocol/hooks'
import { PageWrapper, ThemedBackgroundGlobal } from 'pages/styled'
import TransactionsTable from '../../components/TransactionsTable'
import { useActiveNetworkVersion } from 'state/application/hooks'
import styled from 'styled-components'
import { ChartCard } from 'components/Card'
import BarChart from 'components/BarChart/alt'
import { DailyInfoElement } from 'pages/Home'
import AccountIcon from '../../assets/svg/account-circle.svg'
import LockResetIcon from '../../assets/svg/lock-reset.svg'
import ChronosHourglass from '../../assets/svg/hourglass.svg'

const mockLockedPercentageData = [
  {
    time: '2021-05-04',
    value: 9776779564.410028,
  },
  {
    time: '2021-06-01',
    value: 27325727143.558937,
  },
  {
    time: '2021-07-01',
    value: 29489580080.30014,
  },
  {
    time: '2021-08-01',
    value: 42334225931.38407,
  },
  {
    time: '2021-09-01',
    value: 37197934164.16583,
  },
  {
    time: '2021-10-01',
    value: 46592309626.11137,
  },
  {
    time: '2021-11-01',
    value: 60891700474.78519,
  },
  {
    time: '2021-12-01',
    value: 67772550910.65133,
  },
  {
    time: '2022-01-01',
    value: 59443563267.58296,
  },
  {
    time: '2022-02-01',
    value: 66439992555.711105,
  },
  {
    time: '2022-03-01',
    value: 80940883981.93616,
  },
  {
    time: '2022-04-01',
    value: 82543487486.72197,
  },
  {
    time: '2022-05-01',
    value: 75577667663.38441,
  },
  {
    time: '2022-06-01',
    value: 74362094558.96394,
  },
  {
    time: '2022-07-01',
    value: 88779812349.30049,
  },
  {
    time: '2022-08-01',
    value: 96681556546.59067,
  },
  {
    time: '2022-09-01',
    value: 84386320006.5399,
  },
  {
    time: '2022-10-01',
    value: 78286501935.75647,
  },
  {
    time: '2022-11-01',
    value: 65815260817.538994,
  },
  {
    time: '2022-12-01',
    value: 62316546360.963844,
  },
  {
    time: '2023-01-01',
    value: 63407506732.47787,
  },
  {
    time: '2023-02-01',
    value: 61646015666.80375,
  },
  {
    time: '2023-03-01',
    value: 61700523555.781815,
  },
  {
    time: '2023-04-01',
    value: 61765413062.37656,
  },
  {
    time: '2023-05-01',
    value: 62404344321.10989,
  },
  {
    time: '2023-06-01',
    value: 60330492663.99845,
  },
  {
    time: '2023-07-01',
    value: 65536183215.898735,
  },
  {
    time: '2023-08-01',
    value: 31283984768.585335,
  },
]

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 16px;
`

const ChartWrapper = styled(ChartCard)`
  width: 49%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
`};
`

const CHRChartWrapper = styled(ChartCard)`
  width: 100%;
`

const InfoWrapper = styled.div`
  display: flex;
  position: relative;
  border-radius: 20px;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const LeftAlignContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const ChartTitle = styled.span`
  font-size: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
font-size: 14px;
`};
`

const ChartsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
gap: 16px;
`};
`

const InfoRowContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
`};
`

const LockedHoldersContainer = styled.div`
  display: flex;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`

export default function VeCHRAnalyticsPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const theme = useTheme()

  const [activeNetwork] = useActiveNetworkVersion()

  const [transactions] = useProtocolTransactions()

  const [lockedPercentageHover, setLockedPercentageHover] = useState<number | undefined>()
  const [lockedPercentageLabel, setLockedPercentageLabel] = useState<string | undefined>()
  const [averageLockedLabel, setAverageLockedLabel] = useState<string | undefined>()
  const [lockedHolderLabel, setLockedHolderLabel] = useState<string | undefined>()

  useEffect(() => {
    setLockedPercentageHover(undefined)
  }, [activeNetwork])

  return (
    <PageWrapper>
      <ThemedBackgroundGlobal backgroundColor={activeNetwork.bgColor} />
      <ContentContainer>
        <RowFixed>
          <TYPE.main fontSize="30px">veCHR Analytics</TYPE.main>
        </RowFixed>
        <ContentContainer>
          <ChartsContainer>
            <ChartWrapper>
              <BarChart
                height={220}
                minHeight={332}
                data={mockLockedPercentageData}
                setValue={setLockedPercentageHover}
                setLabel={setLockedPercentageLabel}
                value={lockedPercentageHover}
                label={lockedPercentageLabel}
                topLeft={
                  <InfoWrapper>
                    <LeftAlignContainer>
                      <ChartTitle>Locked CHR Percentage</ChartTitle>
                      <TYPE.subHeader fontSize="12px">(Locked/Circulate)</TYPE.subHeader>
                    </LeftAlignContainer>
                  </InfoWrapper>
                }
              />
            </ChartWrapper>
            <ChartWrapper>
              <BarChart
                height={220}
                minHeight={332}
                data={mockLockedPercentageData}
                setValue={setLockedPercentageHover}
                setLabel={setLockedPercentageLabel}
                value={lockedPercentageHover}
                label={lockedPercentageLabel}
                topLeft={
                  <InfoWrapper>
                    <LeftAlignContainer>
                      <ChartTitle>Average Locked CHR Duration</ChartTitle>
                      <TYPE.subHeader fontSize="12px">(2*veCHR/Locked)</TYPE.subHeader>
                    </LeftAlignContainer>
                  </InfoWrapper>
                }
              />
            </ChartWrapper>
          </ChartsContainer>
          <LockedHoldersContainer>
            <CHRChartWrapper>
              <BarChart
                height={220}
                minHeight={332}
                data={mockLockedPercentageData}
                setValue={setLockedPercentageHover}
                setLabel={setLockedPercentageLabel}
                value={lockedPercentageHover}
                label={lockedPercentageLabel}
                topLeft={
                  <InfoWrapper>
                    <LeftAlignContainer>
                      <ChartTitle>Average Locked CHR Duration</ChartTitle>
                      <TYPE.subHeader fontSize="12px">(2*veCHR/Locked)</TYPE.subHeader>
                    </LeftAlignContainer>
                  </InfoWrapper>
                }
              />
            </CHRChartWrapper>
            <InfoRowContainer>
              <DailyInfoElement title="Total veCHR" value="36,576,007 veCHR" icon={ChronosHourglass} />
              <DailyInfoElement title="Average Locked Duration" value="1.86 Years" icon={LockResetIcon} />
              <DailyInfoElement title="veCHR Holders" value="3,919 Holders" icon={AccountIcon} />
            </InfoRowContainer>
          </LockedHoldersContainer>
        </ContentContainer>
        <TableRowBetween>
          <TYPE.subHeader fontSize="30px">Transactions</TYPE.subHeader>
        </TableRowBetween>
        {transactions ? <TransactionsTable transactions={transactions} color={activeNetwork.primaryColor} /> : null}
      </ContentContainer>
    </PageWrapper>
  )
}
