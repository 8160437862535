import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { save, load } from 'redux-localstorage-simple'

import application from './application/reducer'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import lists from './lists/reducer'
import global from './global/reducer'
import voter from './voter/reducer'
import multicall from 'lib/state/multicall'
// import multicall from './multicall/reducer'
import protocol from './protocol/reducer'
import tokens from './tokens/reducer'
import pools from './pools/reducer'
import gauges from './gauges/reducer'

const PERSISTED_KEYS: string[] = ['user', 'lists']

export const NETWORK_CHAIN_ID: string = process.env.REACT_APP_CHAIN_ID ?? '1'

const store = configureStore({
  reducer: {
    application,
    user,
    multicall: multicall.reducer,
    // multicall,
    lists,
    voter,
    protocol,
    tokens,
    pools,
    global,
    gauges,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, immutableCheck: false }).concat(save({ states: PERSISTED_KEYS })),
  preloadedState: load({ states: PERSISTED_KEYS }),
})

setupListeners(store.dispatch)
store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
